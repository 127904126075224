import axios from "axios";
import { cloudFunction } from "../../constants/siteVariables";

class CloudService {
  async createNewUser(data: any) {
    try {
      const res = await axios.post(`${cloudFunction}/createNewUser`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async createDevice(data: any) {
    try {
      const res = await axios.post(`${cloudFunction}/createdevice`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async paymentDetails({ custId, subId }: { custId: string; subId: string }) {
    try {
      const res = await axios.get(
        `${cloudFunction}/mollieApi?custId=${custId}&subId=${subId}`
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new CloudService();
