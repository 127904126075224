import { useEffect, useState } from "react";
import { HiCheckCircle, HiX } from "react-icons/hi";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import "./StaffMemberDetails.css";
import { Box, Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import { doc, updateDoc } from "firebase/firestore";
import { database } from "../..";
import Alert from "../../components/Alert/Alert";
import {
  RESOURCE,
  defaultAuthorization,
} from "../../context/UserAuthorizationContext";
import { trackAnalytics } from "../../utils/TrackUtils";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  member: any;
  staffMembers: any;
  setStaffMembers: any;
};

export default function StaffMemberDetails({
  onClose,
  member: memberDetails,
  staffMembers,
  setStaffMembers,
}: Props) {
  const [member, setMember] = useState({
    authorization: {
      ...defaultAuthorization,
      ...memberDetails.authorization,
    },
  });
  const [parentAuthorization, setParentAuthorization] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const newObj: any = Object.entries(member.authorization).reduce(
      (acc: any, [key, value]) => {
        const newKey: any = key.split("_")[0];
        acc[newKey] = value;
        return acc;
      },
      {}
    );
    setParentAuthorization(newObj);
  }, [memberDetails]);

  const groupedAuthorization = Object.entries(member.authorization).reduce(
    (grouped: any, [key, value]) => {
      const [group, subgroup] = key.split("_");
      if (!grouped[group]) {
        grouped[group] = [];
      }
      grouped[group].push({ subgroup, value });
      return grouped;
    },
    {}
  );

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const staffRef = doc(database, "StaffMembers", memberDetails.uid);
      try {
        await updateDoc(staffRef, member);
        const updatedItems = staffMembers.map(
          (obj: { id: string; uid: string; email: string }) => {
            if (obj.id === memberDetails.uid) {
              const temp = { userId: obj.uid, email: obj.email };
              trackAnalytics(RESOURCE.ACTIVITY_PERMISSIONS, temp, true);

              return { ...obj, ...member };
            }
            return obj;
          }
        );
        setStaffMembers(updatedItems);
        onClose();
      } catch (error) {
        trackAnalytics(RESOURCE.ACTIVITY_PERMISSIONS, error, false);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };
  const { t } = useTranslation();
  return (
    <Stack>
      <div className="DialogOverlay" />
      <div className="DialogContainer">
        <div className="MemberDialog">
          <h2>{t("Member Details")}</h2>
          <Spacer height={4} />
          <p>
            {t("Edit permissions for this member")} {memberDetails?.email}.
          </p>
          {error && <Alert>{error}</Alert>}
          <Spacer height={24} />
          {parentAuthorization ? (
            <Grid
              container
              spacing={2.5}
              sx={{
                ml: "15px",
              }}
            >
              {Object.entries(groupedAuthorization).map(
                ([group, items]: any) => {
                  const isParentChecked = parentAuthorization[group];
                  return (
                    <Grid lg={3} md={3} sm={4} xs={6}>
                      <Box
                        key={group}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                          fontSize: "0.9rem",
                        }}
                      >
                        <FormControlLabel
                          label={group.charAt(0) + group.slice(1).toLowerCase()}
                          sx={{
                            ".MuiTypography-root": {
                              fontWeight: 550, // add font weight here
                              fontSize: "0.9rem", // add font size here
                            },
                            color: "#F9572B",
                            "&:hover": {
                              color: "white",
                              borderRadius: 10,
                              backgroundColor: "rgb(34 34 34 / 0.54)",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={isParentChecked}
                              onChange={(e) => {
                                const newParentAuthorization = {
                                  ...parentAuthorization,
                                };
                                newParentAuthorization[group] =
                                  e.target.checked;
                                setParentAuthorization(newParentAuthorization);

                                const newAuthorization = {
                                  ...member.authorization,
                                };
                                items.forEach(({ subgroup }: any) => {
                                  newAuthorization[`${group}_${subgroup}`] =
                                    e.target.checked;
                                });
                                setMember({
                                  authorization: newAuthorization,
                                });
                              }}
                              color="warning"
                              sx={{
                                color: "#F9572B",
                                "&:hover": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                        {items.map(({ subgroup, value }: any) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 4,
                                mt: -1,
                              }}
                            >
                              <FormControlLabel
                                key={`${group}_${subgroup}`}
                                label={
                                  subgroup[0].toUpperCase() +
                                  subgroup.slice(1).toLowerCase()
                                }
                                sx={{
                                  color: "#F9572B",
                                  ".MuiTypography-root": {
                                    fontWeight: 500,
                                    fontSize: "0.8rem",
                                  },
                                  "&:hover": {
                                    color: "white",
                                    borderRadius: 10,
                                    backgroundColor: "rgb(34 34 34 / 0.54)",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                      const newAuthorization = {
                                        ...member.authorization,
                                      };
                                      newAuthorization[`${group}_${subgroup}`] =
                                        e.target.checked;
                                      setMember({
                                        authorization: newAuthorization,
                                      });

                                      const newParentAuthorization = {
                                        ...parentAuthorization,
                                      };
                                      const isGroupChecked = items.every(
                                        ({ subgroup: itemSubgroup }: any) =>
                                          newAuthorization[
                                            `${group}_${itemSubgroup}`
                                          ]
                                      );
                                      newParentAuthorization[group] =
                                        isGroupChecked;
                                      setParentAuthorization(
                                        newParentAuthorization
                                      );
                                    }}
                                    color="warning"
                                    sx={{
                                      color: "#F9572B",
                                      "& .MuiSvgIcon-root": { fontSize: 18 },
                                      "&:hover": {
                                        color: "white",
                                      },
                                    }}
                                  />
                                }
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
          ) : (
            <CircularLoading />
          )}

          <div
            className="generate-key-dialog-buttons"
            style={{ marginTop: 20 }}
          >
            <Button loading={loading} onClick={handleUpdate}>
              <HiCheckCircle />
              {t("Update")}
            </Button>

            <Button onClick={onClose}>
              <HiX />
              {t("Close")}
            </Button>
          </div>
        </div>
      </div>
    </Stack>
  );
}
