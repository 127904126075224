import { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import "./VerifyOTP.css";

// Images
import Logo from "../../assets/images/transparent-app_icon.png";
import Alert from "../../components/Alert/Alert";
import { MuiOtpInput } from "mui-one-time-password-input";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { setPage } from "../../slices/pageSlice";
import { User } from "firebase/auth";
import {
  completeSignInViaOtp,
  enrollUserInMultiFactorAuthentication,
} from "../../utils/FirestoreUtil";
import { useTranslation } from "react-i18next";
import { senifone_auth_timer_key } from "../../constants/siteVariables";

/**
 * [VerifyOTP](./README.md) component is displayed when the user is not logged in.
 *
 * @returns {JSX.Element}
 */
export default function VerifyOTP({ user }: { user: User }): JSX.Element {
  // State
  const [otp, setOtp] = useState("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const verifyOtp = async () => {
    setLoading(true);
    try {
      if (user) await enrollUserInMultiFactorAuthentication(otp, user);
      else await completeSignInViaOtp(otp);
      localStorage.setItem(
        senifone_auth_timer_key,
        new Date().getTime().toString()
      );
      dispatch(setPage("home"));
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
  };

  // Render
  return (
    <div className="VerifyOTPContainer">
      <div className="VerifyOTP">
        <div className="VerifyOTP__Title">
          <img src={Logo} alt="Admin Tools" />
          <h1>{t("Two Factor Authentication")}</h1>
          <p>{t("A verification code has been sent to your phone")}.</p>
        </div>
        <div id="recaptcha-container"></div>
        {error && <Alert style={{ marginTop: "12px" }}>{error}</Alert>}
        <div className="VerifyOTP__Form">
          <MuiOtpInput
            length={6}
            value={otp}
            onChange={(value) => setOtp(value)}
            autoFocus
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                // Login
                setLoading(true);
                verifyOtp();
              }
            }}
            sx={{
              "& .MuiInputBase-input": {
                background: "#303030",
                color: "#FFFFFF",
              },
              "@media (max-width: 425px)": {
                "& .MuiInputBase-input": {
                  height: "0.5em",
                },
              },
              "@media (max-width: 375px)": {
                gap: "5px", // Adjust the gap value as per your requirement
              },
            }}
          />
        </div>
        <div className="VerifyOTP__Actions">
          <Button
            loading={loading}
            onClick={() => {
              verifyOtp();
            }}
          >
            {t("Continue")}
            <HiArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
}
