import { configureStore } from "@reduxjs/toolkit";
import { pageSlice } from "./slices/pageSlice";
import { translationSlice } from "./slices/translationSlice";

export default configureStore({
  reducer: {
    translationSlice: translationSlice.reducer,
    pageSlice: pageSlice.reducer,
  },

  // Allow non-serializable values to be passed to the store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
