import JSZip from "jszip";
import { IFileData } from "../models/TranslationFileData";
import ITranslationData, {
  ICategoryModel,
  ITranslationModel,
} from "../models/TranslationModel";

export abstract class AppUtil {
  public static dateToLocaleString(date: Date): string {
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  public static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  public static generateXmlFileData(
    categories: ICategoryModel[],
    translation: ITranslationData
  ): string {
    let xmlData = "";

    // Add the xml header
    xmlData += `<?xml version="1.0" encoding="utf-8"?>\n`;

    // Add 'resources' tag
    xmlData += `<resources>\n`;

    // Add line break
    xmlData += "\n";

    // Add the language name
    xmlData += `<!-- Language: ${translation.name} -->\n`;

    // Loop through all categories
    categories.forEach((category) => {
      // Add the category name
      xmlData += `<!-- Category: ${category.name} | Icon: ${category.icon} -->\n`;

      // Loop through all keys
      category.keys.forEach((key) => {
        // Get the translation for the key
        const translationModel = translation.translations.find(
          (translationModel) => {
            return translationModel.key === key;
          }
        );

        // If the translation is not null, then add it to the xml
        if (!AppUtil.isNullOrUndefined(translationModel)) {
          const value = translationModel!.value.replace(/"/g, '\\"');

          xmlData += `<string name="${key}">${value}</string>\n`;
        } else {
          xmlData += `<string name="${key}"></string>\n`;
        }
      });

      // Add a new line
      xmlData += "\n";
    });

    // Add the closing 'resources' tag
    xmlData += `</resources>\n`;

    // Add a new line
    xmlData += "\n";

    return xmlData;
  }

  public static generateXmlFiles(
    categories: ICategoryModel[],
    translations: ITranslationData[]
  ) {
    // Files to download
    const files: File[] = [];

    // Loop through all translations
    translations.forEach((translation) => {
      // Generate the xml file data
      const xmlData = AppUtil.generateXmlFileData(categories, translation);

      // Create the file
      const file = new File([xmlData], `strings-${translation.locale}.xml`, {
        type: "text/plain",
      });

      files.push(file);
    });

    // Create the zip file
    const zip = new JSZip();

    // Add all files to the zip file
    files.forEach((file) => {
      zip.file(file.name, file);
    });

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create the file
      const file = new File([content], "senifone.translations", {
        type: "application/zip",
      });

      // Create the download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(file);
      downloadLink.download = file.name;
      downloadLink.click();

      // Remove the download link
      document.body.removeChild(downloadLink);
    });
  }

  public static parseFileData(
    data: IFileData[],
    onDataGenerated: (
      categories: ICategoryModel[],
      translations: ITranslationData[]
    ) => void
  ): void {
    let fileData: IFileData[] = data;

    // Remove macos generated files
    fileData = fileData.filter((fileData) => {
      // If the file name starts with __MACOSX, then it is a macos generated file
      return !fileData.name.startsWith("__MACOSX");
    });

    // Generate categories and keys data
    const categories: ICategoryModel[] = [];

    // From first file, extract the category name and the keys
    const firstFileData = fileData[0];
    const firstFileLines = firstFileData.data.split(/\r?\n/);

    // Loop through all lines
    firstFileLines.forEach((line) => {
      // Trim the line
      line = line.trim();

      // If the line starts with <!-- Category: , then it is a category
      if (line.startsWith("<!-- Category:")) {
        // Get the category name till |
        const categoryName = line.substring(15, line.indexOf("|")).trim();

        // Get the icon name from Icon: till -->
        const iconName = line
          .substring(line.indexOf("Icon:") + 6, line.indexOf(" -->"))
          .trim();

        // Generate random id
        const id = Math.random().toString(36).substring(7);

        // Add the category to the list
        categories.push({
          id: id,
          name: categoryName,
          icon: iconName,
          keys: [],
        });
      }

      // If the line starts with <string, then it is a key
      if (line.startsWith("<string")) {
        // Split the string by > and get the first part
        const keyNameParts = line.split(">");
        // Get the key name, without the quotes
        const keyName = keyNameParts[0]
          .replace('<string name="', "")
          .replace('"', "");

        // Add the key to the last category
        categories[categories.length - 1].keys.push(keyName);
      }
    });

    // Generate a list of ITranslationData
    const translationDataList: ITranslationData[] = [];

    // Loop through all files
    fileData.forEach((fileData) => {
      const fileName = fileData.name;
      const data = fileData.data;

      // Get the string between 'strings-' and '.xml'
      const locale = fileName
        .substring(fileName.indexOf("strings-") + 8, fileName.indexOf(".xml"))
        .trim();

      // Get language name from <!-- Language: till -->
      const languageName = data
        .substring(data.indexOf("<!-- Language:") + 15, data.indexOf(" -->"))
        .trim();

      // Generate ITranslationData
      const translationData: ITranslationData = {
        name: languageName,
        locale: locale,
        translations: [],
      };

      // Store all lines in an array
      const lines = data.split(/\r?\n/);

      // Loop through all lines
      lines.forEach((line) => {
        // Trim the line
        line = line.trim();

        // If the line starts with <string, then it is a key
        if (line.startsWith("<string")) {
          // Split the string by > and get the first part
          const keyNameParts = line.split(">");
          // Get the key name, without the quotes
          const keyName = keyNameParts[0]
            .replace('<string name="', "")
            .replace('"', "");

          // Loop through all categories
          categories.forEach((category) => {
            // Loop through all keys
            category.keys.forEach((key) => {
              // If the key name matches the key
              if (key === keyName) {
                // Get the value
                let value = line
                  .replace('<string name="' + keyName + '">', "")
                  .replace("</string>", "");

                // Remove escape characters
                value = value.replace("\\", "");

                // Generate random id
                const id = Math.random().toString(36).substring(7);

                // Add the translation to the list
                const translation: ITranslationModel = {
                  id: id,
                  categoryId: category.id,
                  key: keyName,
                  value: value,
                };

                // Add the translation to the list
                translationData.translations.push(translation);
              }
            });
          });
        }
      });

      // Add the translation data to the list
      translationDataList.push(translationData);
    });

    // Call the callback
    onDataGenerated(categories, translationDataList);
  }
}
