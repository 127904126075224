import { onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import AuthForm from "./components/AuthForm/AuthForm";
import CircularLoading from "./components/CircularLoading/CircularLoading";
import TitleBar from "./components/TitleBar/TitleBar";
import { auth } from ".";
import "./App.css";
import PageRouter from "./PageRouter";
import { useSelector } from "react-redux";

/**
 * Entry point for the [Senifone Admin Tools](../README.md) application.
 *
 * @returns {JSX.Element}
 */
function App(): JSX.Element {
  const page = useSelector((state: any) => state.pageSlice.page);
  // State
  const [validating, setValidating] = useState(true);

  // Firebase User State
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Firebase Auth Listener
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setValidating(false);
    });
  }, []);

  return (
    <div className="App">
      <div className="TitleBarContainer">
        <TitleBar user={user?.emailVerified ? user : null} />
      </div>
      <div className="PageContainer">
        {validating ? (
          <div className="AppLoader">
            <CircularLoading size={32} strokeWidth={3} />
          </div>
        ) : (user === null || !user?.emailVerified) && page !== "verify-otp" ? (
          <AuthForm setUser={setUser} />
        ) : (
          <PageRouter user={user as User} />
        )}
      </div>
    </div>
  );
}

export default App;
