import { User, getAuth, multiFactor } from "firebase/auth";
import { useState } from "react";
import { HiArrowRight, HiPhone } from "react-icons/hi";

import "../../components/AuthForm/AuthForm.css";

// Images
import Logo from "../../assets/images/transparent-app_icon.png";
import { setPage } from "../../slices/pageSlice";
import { useDispatch } from "react-redux";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import {
  initiatePhoneNumberVerification,
  setupRecaptcha,
} from "../../utils/FirestoreUtil";
import { useTranslation } from "react-i18next";

/**
 * [AuthForm](./README.md) component is displayed when the user is not logged in.
 *
 * @returns {JSX.Element}
 */
export default function MFAEnrollment({ user }: { user: User }): JSX.Element {
  // State
  const [phoneNumber, setPhoneNumber] = useState("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const auth = getAuth();
  const {t} = useTranslation()

  const enrollmentInMFA = async () => {
    setLoading(true);
    // Check if the phone is valid
    if (!phoneNumber) {
      setError(`${t("Please enter your phone number")}`);
      return;
    }
    try {
      setupRecaptcha();
      multiFactor(user)
        .getSession()
        .then(function (multiFactorSession) {
          return initiatePhoneNumberVerification(
            phoneNumber,
            multiFactorSession
          );
        })
        .then(function (verificationId) {
          dispatch(setPage("verify-otp"));
          window.verificationId = verificationId;
        })
        .catch((error) => {
          window.recaptchaVerifier.clear();
          if (error.code === "auth/requires-recent-login") {
            auth.signOut();
          }
          setLoading(false);
          setError(error.message);
        });
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Render
  return (
    <div className="AuthFormContainer">
      <div className="AuthForm">
        <div className="AuthForm__Title">
          <img src={Logo} alt="Admin Tools" />
          <h1>{t('Enable MFA')}</h1>
          <p>
            {t('Enter your phone number to enroll into multi factor authentication')}
          </p>
        </div>
        <div id="recaptcha-container"></div>
        {error && <Alert style={{ marginTop: "12px" }}>{error}</Alert>}
        <div className="AuthForm__Form">
          <TextField
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            prefix={<HiPhone />}
            onChange={(event) => setPhoneNumber(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                enrollmentInMFA();
              }
            }}
            fullWidth
          />
        </div>

        {/* Actions */}
        <div className="AuthForm__Actions">
          <Button
            loading={loading}
            onClick={() => {
              enrollmentInMFA();
            }}
          >
            {t("Continue")}
            <HiArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
}
