import { KeyboardRounded } from "@mui/icons-material";
import IInputData from "../Data/InputData";
import { HiArrowCircleLeft, HiCollection, HiDeviceMobile, HiHome } from "react-icons/hi";

type Props = {
    sendData: (data: IInputData) => void;
    keyEventFocus: boolean;
    onKeyEventFocus: () => void;
};

export default function RemoteDeviceControls(props: Props) {
    return (
        <ul>
            <li>
                <button onClick={() => {
                    const strData: IInputData = {
                        type: "home",
                        data: "home",
                    }
                    props.sendData(strData);
                }}>
                    <HiHome />
                </button>
            </li>
            <li>
                <button onClick={() => {
                    const strData: IInputData = {
                        type: "back",
                        data: "back",
                    }
                    props.sendData(strData);
                }}>
                    <HiArrowCircleLeft />
                </button>
            </li>
            <li>
                <button onClick={() => {
                    const strData: IInputData = {
                        type: "recent",
                        data: "recent",
                    }
                    props.sendData(strData);
                }}>
                    <HiCollection />
                </button>
            </li>
            <li>
                <button onClick={() => {
                    const strData: IInputData = {
                        type: "notificationPanel",
                        data: "notificationPanel",
                    }
                    props.sendData(strData);
                }}>
                    <HiDeviceMobile />
                </button>
            </li>
            <li>
                <button onClick={props.onKeyEventFocus}>
                    <KeyboardRounded />
                </button>
            </li>
        </ul>
    );
}