import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Button, { ButtonType } from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import "./CreateUser.css";
import { FormHelperText, Grid, Stack } from "@mui/material";
import TextField from "../../components/TextField/TextField";
import { FaUser } from "react-icons/fa";
import {
  RESOURCE,
  defaultAuthorization,
  useUserAuthorization,
} from "../../context/UserAuthorizationContext";
import { Controller, useForm } from "react-hook-form";
import Permissions from "./Permissions";
import CloudService from "../../services/Cloud";
import { createNewStaffMember } from "../../utils/FirestoreUtil";
import Alert from "../../components/Alert/Alert";
import { trackAnalytics } from "../../utils/TrackUtils";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};

export default function CreateUser({ onClose }: Props) {
  const { user } = useUserAuthorization();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [member, setMember] = useState<any>({
    authorization: defaultAuthorization,
  });
  const [parentAuthorization, setParentAuthorization] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newObj: any = Object.entries(member.authorization).reduce(
      (acc: any, [key, value]) => {
        const newKey: any = key.split("_")[0];
        acc[newKey] = value;
        return acc;
      },
      {}
    );
    setParentAuthorization(newObj);
  }, []);

  const groupedAuthorization = Object.entries(member.authorization).reduce(
    (grouped: any, [key, value]) => {
      const [group, subgroup] = key.split("_");
      if (!grouped[group]) {
        grouped[group] = [];
      }
      grouped[group].push({ subgroup, value });
      return grouped;
    },
    {}
  );

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const res = await CloudService.createNewUser(data);
      if (res?.uid) {
        const userObj = {
          email: res.email,
          uid: res.uid,
          photoURL: null,
          phoneNumber: null,
          displayName: null,
        };
        try {
          await createNewStaffMember(userObj, member?.authorization);
          trackAnalytics(RESOURCE.USER_CREATE, userObj, true);
        } catch (error) {
          trackAnalytics(RESOURCE.USER_CREATE, error, false);
        }
        onClose();
      } else {
        setError(res);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <Stack>
      <div className="DialogOverlay" />
      <div className="DialogContainer">
        <div className="UserDialog">
          <h2>{t("Create User")}</h2>
          <Spacer height={4} />
          <p>{t("Add an email/password for new user")}.</p>
          {error && <Alert>{error}</Alert>}
          <Spacer height={24} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems={"center"}>
              <Grid item lg={2} md={2} xs={2}>
                <div className="TextField__Title">{t("Email")}:</div>
              </Grid>
              <Grid item lg={10} md={10} xs={10}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="Enter a email"
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Spacer height={10} />
            <Grid container alignItems={"center"}>
              <Grid item lg={2} md={2} xs={3}>
                <div className="TextField__Title">{t("Password")}:</div>
              </Grid>
              <Grid item lg={10} md={10} xs={9}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: `${t("Password is required")}`,
                    minLength: {
                      value: 6,
                      message: t("Password must be at least 6 characters"),
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        fullWidth
                        type="password"
                        placeholder={`${t("Enter a password")}`}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
              {errors.password && errors.email && (
                <FormHelperText error>/</FormHelperText>
              )}
              {errors.password && (
                <FormHelperText error>{errors.password.message}</FormHelperText>
              )}
            </Stack>

            <Spacer height={24} />
            <div className="TextField__Title">{t('Allowed Permissions')}</div>

            <Permissions
              parentAuthorization={parentAuthorization}
              groupedAuthorization={groupedAuthorization}
              setParentAuthorization={setParentAuthorization}
              setMember={setMember}
              member={member}
            />

            <div
              className="generate-key-dialog-buttons"
              style={{ marginTop: 20 }}
            >
              <Button
                buttonType="submit"
                loading={loading}
                type={ButtonType.Success}
              >
                <FaUser />
                {t('Create')}
              </Button>

              <Button onClick={onClose}>
                <HiX />
                {t('Close')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Stack>
  );
}
