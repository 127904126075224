import { onAuthStateChanged } from "firebase/auth";
import PropTypes from "prop-types";
import {
  createContext,
  useState,
  useEffect,
  ReactElement,
  useContext,
} from "react";
import { auth, database } from "..";
import { createNewStaffMember, getStaffMember } from "../utils/FirestoreUtil";
import { doc, updateDoc } from "firebase/firestore";
import { senifone_auth_timer_key } from "../constants/siteVariables";

// ----------------------------------------------------------------------

export enum APP_AUTHORIZATION {
  "TRANSLATION_VIEW" = "TRANSLATION_VIEW",
  "DEVICES_VIEW" = "DEVICES_VIEW",
  "DEVICES_ACTION" = "DEVICES_ACTION",
  "DEVICES_LOCATION" = "DEVICES_LOCATION",
  "RESETKEYS_VIEW" = "RESETKEYS_VIEW",
  "SOSUSAGE_VIEW" = "SOSUSAGE_VIEW",
  "SOSUSAGE_LOCATION" = "SOSUSAGE_LOCATION",
  "SOSUSAGE_DEVICE" = "SOSUSAGE_DEVICE",
  "STAFFMEMBERS_VIEW" = "STAFFMEMBERS_VIEW",
  "QR_GENERATE" = "QR_GENERATE",
  "ANALYTICS_VIEW" = "ANALYTICS_VIEW",
  "REMOTE_VIEW" = "REMOTE_VIEW",
  "STATS_VIEW" = "STATS_VIEW",
  "LEASES_VIEW" = "LEASES_VIEW"
}

export enum RESOURCE {
  "USER_CREATE" = "USER_CREATE",
  "KEY_GENERATE" = "KEY_GENERATE",
  "ACTIVITY_DEVICE" = "ACTIVITY_DEVICE",
  "ACTIVITY_PERMISSIONS" = "ACTIVITY_PERMISSIONS",
}

export enum TIERS {
  "FREE_TIER" = "FREE_TIER",
  "PAID_TIER" = "PAID_TIER",
  "COMPANION_TIER" = "COMPANION_TIER",
}

export const defaultAuthorization = {
  [APP_AUTHORIZATION.TRANSLATION_VIEW]: false,
  [APP_AUTHORIZATION.DEVICES_VIEW]: false,
  [APP_AUTHORIZATION.RESETKEYS_VIEW]: false,
  [APP_AUTHORIZATION.SOSUSAGE_VIEW]: false,
  [APP_AUTHORIZATION.STAFFMEMBERS_VIEW]: false,
  [APP_AUTHORIZATION.QR_GENERATE]: false,
  [APP_AUTHORIZATION.DEVICES_ACTION]: false,
  [APP_AUTHORIZATION.DEVICES_LOCATION]: false,
  [APP_AUTHORIZATION.SOSUSAGE_LOCATION]: false,
  [APP_AUTHORIZATION.SOSUSAGE_DEVICE]: false,
  [APP_AUTHORIZATION.ANALYTICS_VIEW]: false,
  [APP_AUTHORIZATION.REMOTE_VIEW]: false,
  [APP_AUTHORIZATION.STATS_VIEW]: false,
  [APP_AUTHORIZATION.LEASES_VIEW]: false
};

interface AuthorizationStateType {
  userAuthorization: { [key in APP_AUTHORIZATION]: boolean };
  user: any;
}

const initialState = {
  userAuthorization: defaultAuthorization,
  user: {},
};

const UserAuthorizationContext =
  createContext<AuthorizationStateType>(initialState);

// ----------------------------------------------------------------------

UserAuthorizationProvider.propTypes = {
  children: PropTypes.node,
};

export function UserAuthorizationProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [userAuthorization, setUserAuthorization] = useState(
    initialState.userAuthorization
  );
  const [user, setUser] = useState(initialState.user);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);

        if (localStorage.getItem(senifone_auth_timer_key)) {
          const prevTime: Date | string | any = new Date(
            localStorage.getItem(senifone_auth_timer_key) || ""
          );
          const currTime: Date | any = new Date();

          const differenceInMilliseconds = currTime - prevTime;
          const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

          if (differenceInHours > 24) {
            localStorage.removeItem(senifone_auth_timer_key);
            auth.signOut();
            return;
          }
        }

        try {
          const userAuthorization: any = await getStaffMember(user.uid);

          if (userAuthorization) {
            // @ts-ignore
            setUserAuthorization(userAuthorization.authorization);
            if (
              Object.keys(defaultAuthorization).length !==
              Object.keys(userAuthorization.authorization).length
            ) {
              const staffRef = doc(database, "StaffMembers", user.uid);
              await updateDoc(staffRef, {
                authorization: {
                  ...defaultAuthorization,
                  ...userAuthorization.authorization,
                },
              });
            }
          } else {
            await createNewStaffMember(user, defaultAuthorization);
            setUserAuthorization(defaultAuthorization);
          }
        } catch (error) {
          console.log("☄️☄️☄️☄️💥☄️☄️☄️☄️ error", error);
        }
      } else {
      }
    });
  }, [user]);

  return (
    // @ts-ignore
    <UserAuthorizationContext.Provider value={{ userAuthorization, user }}>
      {children}
    </UserAuthorizationContext.Provider>
  );
}

export const useUserAuthorization = () => {
  return useContext(UserAuthorizationContext);
};
