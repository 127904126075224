import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { database } from "../..";
import Button, { ButtonType } from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import { AppUtil } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Checkbox } from "@mui/material";

type Props = {
  deviceId: string;
  device: DocumentData;
  index: number;
  setConfirmationDialog: (
    confirmationDialog: false | { true: Boolean; id: string; type: string }
  ) => void;
  selectedRows: any;
  toggleSelectRow: any;
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function DeviceRow(props: Props) {
  const { device, index, deviceId, selectedRows, toggleSelectRow } = props;
  const registrationDate = device.registrationDate.seconds * 1000;
  const deviceStatus =
    device.verified == "1"
      ? "Active"
      : device.verified == "-1"
      ? "Deactivated"
      : "Pending";

  const [loading, setLoading] = useState<boolean>(false);
  const [validationLog, setValidationLog] = useState<DocumentData | undefined>(
    undefined
  );

  const getValidationLog = async () => {
    if (loading) return;
    setLoading(true);
    const q = query(
      collection(database, "UserData", deviceId, "ValidationLogs"),
      orderBy("validationDate", "desc"),
      limit(5)
    );
    const result = await getDocs(q);

    if (!result.empty && result.docs.length > 0) {
      setValidationLog(result.docs[0].data());
    }

    setLoading(false);
  };

  useEffect(() => {
    getValidationLog();
  }, []);
  const { t } = useTranslation();
  return (
    <tr key={index}>
      <td>
        <Checkbox
          {...label}
          checked={selectedRows.has(deviceId)}
          onChange={() => toggleSelectRow(deviceId)}
          sx={{
            color: "#F9572B",
            "&.Mui-checked": {
              color: "#F9572B",
            },
          }}
        />
      </td>
      <td>{index + 1}</td>
      <td>
        <span className={deviceStatus}>{deviceStatus}</span>
      </td>
      <td>
        {device.pin} {device.appMode}
      </td>
      <td>
        {device.deviceName} {device.deviceModel}
      </td>
      <td>{device.deviceImei === "null" ? "--" : device.deviceImei}</td>
      <td>{device.registrationIp}</td>
      <td className="ValidationDetailsCol">
        {/* App Version */}
        {loading ? (
          <CircularLoading size={24} strokeWidth={2} />
        ) : validationLog ? (
          validationLog.appVersion
        ) : (
          "--"
        )}

        {/* Check if has location */}
        {validationLog && validationLog.location ? (
          <Button
            onClick={() => {
              const latitude = validationLog.location.latitude;
              const longitude = validationLog.location.longitude;

              // Open Google Maps
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                "_blank"
              );
            }}
          >
            <HiLocationMarker /> {t("Location")}
          </Button>
        ) : null}
      </td>
      <td>{moment(new Date(registrationDate)).format("HH:mm, DD/MM/YY")}</td>
      <td className="DeviceRowActions">
        <Button
          onClick={() => {
            props.setConfirmationDialog({
              true: true,
              id: device.id,
              type: "activate",
            });
          }}
          disabled={deviceStatus === "Active"}
          type={ButtonType.Success}
        >
          {t("Activate")}
        </Button>
        <Spacer width={8} />
        <Button
          onClick={() => {
            props.setConfirmationDialog({
              true: true,
              id: device.id,
              type: "deactivate",
            });
          }}
          disabled={deviceStatus === "Deactivated"}
          type={ButtonType.Danger}
        >
          {t("Deactivate")}
        </Button>
      </td>
    </tr>
  );
}
