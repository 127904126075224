import { DocumentData } from "firebase/firestore";
import Button, { ButtonType } from "../../../components/Button/Button";
import Spacer from "../../../components/Spacer/Spacer";
import { useTranslation } from "react-i18next";

type Props = {
  request: DocumentData;
  index: number;
  onClickConnect: () => void;
  onClickDisable: () => void;
};

export default function FindDeviceRow(props: Props) {
  const { request, index } = props;
  // const requestDate = request.timestamp.seconds * 1000;
  const { t } = useTranslation();
  return (
    <div className="ActiveRoomRow" key={index}>
      <div className="Row">
        {request.pin}
        <div>{request.status}</div>
      </div>

      <div className="DeviceRowActions">
        <Button onClick={props.onClickConnect} type={ButtonType.Success}>
          {t('Connect')}
        </Button>
        <Spacer width={10} />
        <Button onClick={props.onClickDisable} type={ButtonType.Danger}>
          {t('Disable Room')}
        </Button>
      </div>
    </div>
  );
}
