interface ISpacerProps {
    width?: number;
    height?: number;
}

export default function Spacer(props: ISpacerProps) {
    const width = props.width ?? 1;
    const height = props.height ?? 1;
    const display = width === 1 && height === 1 ? "inline-block" : "block";

    return (
        <div className="spacer" style={{ width: width, height: height, display: display }}></div>
    );
}