import { DocumentData } from "firebase/firestore";
import { AppUtil } from "../../../utils/AppUtil";
import Button, { ButtonType } from "../../../components/Button/Button";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  deviceId: string;
  device: DocumentData;
  index: number;
  sendRequest: (deviceId: string, pin: string) => void;
};

export default function FindDeviceRow(props: Props) {
  const [requestSent, setRequestSent] = React.useState<boolean>(false);

  const { device, index, deviceId } = props;

  const { t } = useTranslation();

  return (
    <div className="FindDeviceRow" key={index}>
      <div className="Row">
        <div>
          <span>{t('PIN')}: </span>
          <b>
            {device.pin} {device.appMode}
          </b>
        </div>
        <div>
          <span>{t('IMEI')}: </span>
          <b>{device.deviceImei === "null" ? "--" : device.deviceImei}</b>
        </div>
      </div>
      <div className="Row">
        <div>
          {device.deviceName} {device.deviceModel}
        </div>
        <div>
          <Button
            onClick={() => {
              // Request Remote Control
              props.sendRequest(deviceId, device.pin);
              setRequestSent(true);
            }}
            type={ButtonType.Default}
          >
            {requestSent ? "Resend Request" : "Send Request"}
          </Button>
        </div>
      </div>
    </div>
  );
}
