enum RemoteControlStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTING = "DISCONNECTING",
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}

export default RemoteControlStatus;
