import "./Alert.css";

export enum AlertType {
    Danger = "danger",
    Warning = "warning",
    Info = "info",
    Success = "success"
}

type Props = {
    id?: string;
    className?: string;
    children: React.ReactNode;
    type?: AlertType;
    style?: React.CSSProperties;
}

export default function Alert(props: Props) {
    const type = props.type ?? AlertType.Danger;

    return (
        <div
            id={props.id}
            className={"Alert " + type + (props.className ? " " + props.className : "")}
            style={props.style}
        >
            {props.children}
        </div>
    );
}