import React from "react";

const SubscriptionDetails = ({ subscriptionDetails }: any) => {
  const details = [
    { key: "Amount", value: subscriptionDetails.amount?.value },
    { key: "Description", value: subscriptionDetails.description },
    { key: "Interval", value: subscriptionDetails.interval },
    { key: "Mode", value: subscriptionDetails.mode },
    { key: "Subscription Id", value: subscriptionDetails.id },
    { key: "Times", value: subscriptionDetails.times },
    { key: "Customer Id", value: subscriptionDetails.customerId },
    { key: "Status", value: subscriptionDetails.status },
    { key: "Next Payment Date", value: subscriptionDetails.nextPaymentDate },
    { key: "Resource", value: subscriptionDetails.resource },
    { key: "Start Date", value: subscriptionDetails.startDate },
    { key: "Times Remaining", value: subscriptionDetails.timesRemaining },
  ];

  const column1 = details.slice(0, Math.ceil(details.length / 2));
  const column2 = details.slice(Math.ceil(details.length / 2));

  return (
    <div>
      <h2 className="heading">Details</h2>
      <div className="leaseDetail">
        <div className="container">
          {column1.map((detail, index) => (
            <div className="content" key={index}>
              <h3 className="key">{detail.key} : </h3>
              <p className="value">{detail.value}</p>
            </div>
          ))}
        </div>
        <div className="container">
          {column2.map((detail, index) => (
            <div className="content" key={index}>
              <h3 className="key">{detail.key} : </h3>
              <p className="value">{detail.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
