import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { HiRefresh, HiSearch } from "react-icons/hi";
import Button, { ButtonType } from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog/ConfirmationDialog";
import { database } from "../..";
import DeviceRow from "./DeviceRow";
import "./DevicesPage.css";
import {
  fetchDevicesUsingSearchString,
  getDocumentsCountInsideCollection,
} from "../../utils/FirestoreUtil";
import { appModeFilterTypes, statusFilterTypes } from "../../utils/DevicesUtil";
import {
  RESOURCE,
  useUserAuthorization,
} from "../../context/UserAuthorizationContext";
import { trackAnalytics } from "../../utils/TrackUtils";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";

export default function DevicesPage() {
  const { user } = useUserAuthorization();
  // Component state
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  // Pagination
  const [perPage, setPerPage] = React.useState<number>(10);
  const [lastDoc, setLastDoc] = React.useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  // List of reset keys as DocumentSnapshot
  const [devices, setDevices] = React.useState<DocumentData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Confirmation Dialog
  const [confirmationDialog, setConfirmationDialog] = React.useState<
    false | { true: Boolean; id: string; type: string }
  >(false);

  // Search
  const [searchString, setSearchString] = React.useState<string>("");

  // Counters
  const [totalDevices, setTotalDevices] = React.useState<number>(0);

  // Filter
  const [appModeFilter, setAppModeFilter] = React.useState<appModeFilterTypes>(
    appModeFilterTypes.ALL
  );
  const [statusFilter, setStatusFilter] = React.useState<statusFilterTypes>(
    statusFilterTypes.ALL
  );
  // selection states
  const [confirmSelected, setConfirmSelection] = React.useState<
    false | { true: Boolean; id: string; type: string }
  >(false);
  const [selectedRows, setSelectedRows] = React.useState<Set<string>>(
    new Set()
  );

  const { t } = useTranslation();

  // Get devices keys
  const getDevices = async () => {
    if (loading) return;
    setLoading(true);
    let q;
    switch (true) {
      case appModeFilter !== appModeFilterTypes.ALL &&
        statusFilter !== statusFilterTypes.ALL:
        q = lastDoc
          ? query(
              collection(database, "UserData"),
              where("appMode", "==", appModeFilter),
              where("verified", "==", statusFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage),
              startAfter(lastDoc)
            )
          : query(
              collection(database, "UserData"),
              where("appMode", "==", appModeFilter),
              where("verified", "==", statusFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage)
            );
        break;
      case appModeFilter !== appModeFilterTypes.ALL:
        q = lastDoc
          ? query(
              collection(database, "UserData"),
              where("appMode", "==", appModeFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage),
              startAfter(lastDoc)
            )
          : query(
              collection(database, "UserData"),
              where("appMode", "==", appModeFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage)
            );
        break;
      case statusFilter !== statusFilterTypes.ALL:
        q = lastDoc
          ? query(
              collection(database, "UserData"),
              where("verified", "==", statusFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage),
              startAfter(lastDoc)
            )
          : query(
              collection(database, "UserData"),
              where("verified", "==", statusFilter),
              orderBy("registrationDate", "desc"),
              limit(perPage)
            );
        break;
      default:
        q = lastDoc
          ? query(
              collection(database, "UserData"),
              orderBy("registrationDate", "desc"),
              limit(perPage),
              startAfter(lastDoc)
            )
          : query(
              collection(database, "UserData"),
              orderBy("registrationDate", "desc"),
              limit(perPage)
            );
    }
    const result = await getDocs(q);

    if (result.docs.length < perPage) setHasMore(false);
    const devicesArray: DocumentData[] = devices;
    result.forEach((doc) => {
      // If the document is already in the list, don't add it again
      // TODO:: This might cause performance issues if the list is too long
      if (devicesArray.findIndex((resetKey) => resetKey.id === doc.id) === -1) {
        devicesArray.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });
    setLastDoc(result.docs[result.docs.length - 1]);
    setDevices(devicesArray);
    setLoading(false);
  };

  // Get devices using search string
  const getDevicesUsingSearchString = async () => {
    if (loading) return;
    setLoading(true);
    setDevices([]);
    const requests: Promise<DocumentData>[] = [];
    ["pin", "deviceImei", "uniqueId"].forEach((field) => {
      requests.push(fetchDevicesUsingSearchString(field, searchString));
    });
    await Promise.all(requests).then((values) => {
      setDevices(values.flat());
    });
    setHasMore(false);
    setLastDoc(null);
    setLoading(false);
  };

  // Get reset keys
  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      getDevices();
    }
  }, []);

  React.useEffect(() => {
    if (!initialLoad) {
      getDevices();
    }
  }, [appModeFilter, statusFilter]);

  React.useEffect(() => {
    (async () => {
      const count = await getDocumentsCountInsideCollection("UserData");
      setTotalDevices(count);
    })();
  }, []);

  const toggleSelectRow = (deviceId: string) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(deviceId)) {
      updatedSelectedRows.delete(deviceId);
    } else {
      updatedSelectedRows.add(deviceId);
    }
    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectionAction = async (action: string) => {
    setConfirmSelection({
      true: true,
      id: "",
      type: action === "activate" ? "activate" : "deactivate",
    });
  };

  const toggleSelectAll = () => {
    if (devices.length === 0) {
      return;
    }

    if (selectedRows.size === devices.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(devices.map((device) => device.id)));
    }
  };

  const selectAllCheckbox = (
    <Checkbox
      checked={devices.length > 0 && selectedRows.size === devices.length}
      onChange={toggleSelectAll}
      sx={{
        color: "#F9572B",
        "&.Mui-checked": {
          color: "#F9572B",
        },
      }}
    />
  );

  return (
    <div className="DevicesPage">
      {confirmationDialog && (
        <ConfirmationDialog
          title={
            confirmationDialog.type === "activate"
              ? `${t("Activate Device")}`
              : `${t("Deactivate Device")}`
          }
          message={
            confirmationDialog.type === "activate"
              ? `${t("Are you sure you want to activate this device?")}`
              : `${t("Are you sure you want to deactivate this device?")}`
          }
          onConfirm={async () => {
            const deviceRef = doc(database, "UserData", confirmationDialog.id);
            const obj = {
              verified: confirmationDialog.type === "activate" ? 1 : -1,
            };
            try {
              await updateDoc(deviceRef, obj);
              // Update the list
              const devicesArray: DocumentData[] = devices;
              const index = devicesArray.findIndex(
                (resetKey) => resetKey.id === confirmationDialog.id
              );
              if (index !== -1) {
                devicesArray[index].verified =
                  confirmationDialog.type === "activate" ? 1 : -1;
                setDevices(devicesArray);
              }
              trackAnalytics(RESOURCE.ACTIVITY_DEVICE, obj, true);
            } catch (error) {
              trackAnalytics(RESOURCE.ACTIVITY_DEVICE, error, false);
            }
          }}
          onClose={() => setConfirmationDialog(false)}
        />
      )}

      {confirmSelected && (
        <ConfirmationDialog
          title={
            confirmSelected.type === "activate"
              ? `${t("Activate Devices")}`
              : `${t("Deactivate Devices")}`
          }
          message={
            confirmSelected.type === "activate"
              ? `${t("Are you sure you want to activate")} (${
                  Array.from(selectedRows).length
                }) ${t("devices")}?`
              : `${t("Are you sure you want to deactivate")} (${
                  Array.from(selectedRows).length
                }) ${t("devices")}?`
          }
          onConfirm={async () => {
            const selectedIds = Array.from(selectedRows);
            setLoading(true);

            try {
              await Promise.all(
                selectedIds.map(async (deviceId) => {
                  const deviceRef = doc(database, "UserData", deviceId);
                  const updateValue =
                    confirmSelected.type === "activate" ? 1 : -1;
                  await updateDoc(deviceRef, { verified: updateValue });

                  setDevices((devices) =>
                    devices.map((device) => {
                      if (device.id === deviceId) {
                        return { ...device, verified: updateValue };
                      }
                      return device;
                    })
                  );
                })
              );

              setSelectedRows(new Set());
              setLoading(false);
              setConfirmSelection(false);
            } catch (error) {
              console.error(`Error devices:`, error);
              setLoading(false);
            }
          }}
          onClose={() => setConfirmSelection(false)}
        />
      )}

      <Table
        headerRowItems={[
          selectAllCheckbox,
          "#",
          "Status",
          "PIN",
          "Device",
          "IMEI",
          "IP",
          "Latest",
          "Registration",
          "Action",
        ]}
        headerContent={
          <div className="DevicesHeader">
            <div className="DevicesHeader__Top">
              <div className="DevicesHeader__Left">
                <h3>{t("Devices")}</h3>
                <Spacer height={6} />
                <p>{t("List of all devices registered with the app")}.</p>
              </div>
              <div className="DevicesHeader__Right">
                <div className="DevicesHeaderSelectBox">
                  <select
                    className="DevicesHeader__Select"
                    onChange={(e) => {
                      setAppModeFilter(e.target.value as appModeFilterTypes);
                      setLastDoc(null);
                      setHasMore(true);
                      setDevices([]);
                    }}
                    value={appModeFilter}
                  >
                    <option value={appModeFilterTypes.ALL}>{t("All")}</option>
                    <option value={appModeFilterTypes.PROD}>{t("Prod")}</option>
                    <option value={appModeFilterTypes.DEV}>{t("Dev")}</option>
                  </select>
                  <select
                    className="DevicesHeader__Select"
                    onChange={(e) => {
                      setStatusFilter(
                        parseInt(e.target.value) as statusFilterTypes
                      );
                      setLastDoc(null);
                      setHasMore(true);
                      setDevices([]);
                    }}
                    value={statusFilter}
                  >
                    <option value={statusFilterTypes.ALL}>{t("All")}</option>
                    <option value={statusFilterTypes.ACTIVE}>
                      {t("Active")}
                    </option>
                    <option value={statusFilterTypes.PENDING}>
                      {t("Pending")}
                    </option>

                    <option value={statusFilterTypes.BLOCKED}>
                      {t("Blocked")}
                    </option>
                  </select>
                </div>
                <div className="DevicesSearchBox">
                  <input
                    type="text"
                    placeholder="Search PIN"
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    value={searchString}
                  />
                  <Button
                    className="SearchButton"
                    onClick={() => {
                      if (searchString !== "") {
                        setLastDoc(null);
                        setHasMore(true);
                        setDevices([]);
                        getDevicesUsingSearchString();
                      }
                    }}
                  >
                    <HiSearch />
                    {t("Search")}
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    setLastDoc(null);
                    setHasMore(true);
                    setDevices([]);
                    getDevices();
                    setSearchString("");
                  }}
                >
                  <HiRefresh />
                  {t("Reload All")}
                </Button>
              </div>
            </div>
            <div className="DevicesHeader__Bottom">
              <p>
                {t("Showing")} {devices.length} {t("devices")} ({totalDevices})
              </p>
              <div className="DevicesHeader_Bottom_Select">
                <label>{"Per Page"}</label>
                <select
                  onChange={(e) => {
                    setPerPage(parseInt(e.target.value));
                    setLastDoc(null);
                    setHasMore(true);
                    setDevices([]);
                    getDevices();
                  }}
                  value={perPage}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                {selectedRows.size > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 10,
                    }}
                  >
                    <Button
                      onClick={() => handleSelectionAction("activate")}
                      type={ButtonType.Success}
                    >
                      {t("Activate")}
                    </Button>
                    <Spacer width={8} />
                    <Button
                      onClick={() => handleSelectionAction("deactivate")}
                      type={ButtonType.Danger}
                    >
                      {t("Deactivate")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        footerContent={
          loading ? (
            <div className="Loading">
              <CircularLoading size={24} strokeWidth={3} />
            </div>
          ) : hasMore ? (
            <Button onClick={getDevices}>{t("Load More")}</Button>
          ) : (
            <div className="NoMore">{t("No more devices")}</div>
          )
        }
      >
        {devices.map((device, index) => {
          return (
            <DeviceRow
              key={index}
              deviceId={device.id}
              index={index}
              device={device}
              setConfirmationDialog={setConfirmationDialog}
              selectedRows={selectedRows}
              toggleSelectRow={toggleSelectRow}
            />
          );
        })}
      </Table>
    </div>
  );
}
