import React from "react";
import Button, { ButtonType } from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import "./ConfirmationDialog.css";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  message: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
};

export default function ConfirmationDialog(props: Props): JSX.Element {
  // State
  const [loading, setLoading] = React.useState(false);

  // Confirm
  const confirm = async () => {
    setLoading(true);
    await props.onConfirm();
    setLoading(false);
    props.onClose();
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="ConfirmationDialogOverlay" />
      <div className="ConfirmationDialog">
        <div className="ConfirmationDialogContainer">
          <div className="ConfirmationDialogContent">
            <h3>{props.title}</h3>
            <p>{props.message}</p>
          </div>
          <div className="ConfirmationDialogActions">
            <Button onClick={props.onClose} type={ButtonType.Info}>
              {t('Cancel')}
            </Button>
            <Button onClick={confirm} loading={loading}>
              {t('Confirm')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
