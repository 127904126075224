import "./Table.css";
import { ReactNode } from "react";

type Props = {
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  headerRowItems?: (string | ReactNode)[];
  footerContent?: React.ReactNode;
};

export default function Table(props: Props) {
  return (
    <div className="TableContainer">
      <div className="TableHeader">{props.headerContent}</div>
      <div className="TableContentContainer">
        <table className="Table">
          <thead>
            <tr>
              {props.headerRowItems?.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>{props.children}</tbody>
        </table>
      </div>
      <div className="TableFooter">{props.footerContent}</div>
    </div>
  );
}
