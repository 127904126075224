import { Button, FormHelperText, Grid } from "@mui/material";
import React, { useState } from "react";
import Spacer from "../../components/Spacer/Spacer";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { useUserAuthorization } from "../../context/UserAuthorizationContext";
import { updatePassword } from "firebase/auth";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import { HiKey } from "react-icons/hi";
import TextField from "../../components/TextField/TextField";
import Alert, { AlertType } from "../../components/Alert/Alert";

const AccountChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState("");

  const { t } = useTranslation();
  const { user } = useUserAuthorization();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      setPasswordError("Password must match");
      return;
    }
    setPasswordError("");
    try {
      setLoading(true);
      if (user) {
        await updatePassword(user, data.password as unknown as string)
          .then(() => {
            setSuccess(`${t("Password Changed Successfully")}`);
            reset();
          })
          .catch((error: any) => {
            console.log(error);
            setPasswordError(error.message);
          });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <div className="change-pass">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems={"center"}>
          <Grid item lg={4} md={4} xs={12}>
            <div className="TextField__Title">{t("Password")}:</div>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: `${t("Password is required")}`,
                minLength: {
                  value: 6,
                  message: t("Password must be at least 6 characters"),
                },
              }}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    fullWidth
                    prefix={<HiKey />}
                    type="password"
                    placeholder={`${t("Enter a password")}`}
                  />
                </>
              )}
            />
            <Stack direction={"row"} justifyContent={"flex-end"}>
              {errors.password && (
                <FormHelperText error>{errors.password.message}</FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Spacer height={10} />
        <Grid container justifyContent={"end"} alignItems={"center"}>
          <Grid item lg={4} md={4} xs={12}>
            <div className="TextField__Title">{t("Confirm Password")}:</div>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: `${t("Confirm Password is required")}`,
                minLength: {
                  value: 6,
                  message: t("Confirm Password must be at least 6 characters"),
                },
              }}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    prefix={<HiKey />}
                    fullWidth
                    type="password"
                    placeholder={`${t("Enter password again")}`}
                  />
                </>
              )}
            />
          </Grid>
          <Stack direction={"column"} justifyContent={"end"}>
            {errors.confirmPassword && (
              <FormHelperText error>
                {errors.confirmPassword.message}
              </FormHelperText>
            )}
            {passwordError && (
              <FormHelperText error>{passwordError}</FormHelperText>
            )}
          </Stack>
          {success && (
            <Stack
              direction={"column"}
              alignItems={"center"}
              mt={2}
              width={"100%"}
            >
              <Alert type={AlertType.Success}>{success}</Alert>
            </Stack>
          )}
        </Grid>

        <div className="AccountButton" style={{ marginTop: 20 }}>
          <Button variant="outlined" type="submit">
            {loading ? (
              <CircularLoading size={25} strokeWidth={3} />
            ) : (
              t("Save")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountChangePassword;
