import {
  DocumentData,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import React from "react";
import { database } from "../../..";
import FirestoreUtil from "../../../utils/FirestoreUtil";
import Button from "../../../components/Button/Button";
import StatsUtil from "../StatsUtil";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StatsSOS() {
  const { t } = useTranslation();
  // State
  const [loadingLogs, setLoadingLogs] = React.useState(false); // Loading devices from database
  const [logDocs, setLogDocs] = React.useState<DocumentData[]>([]);

  // Stats
  const [firstSOSDate, setFirstSOSDate] = React.useState<Date | null>(null);

  const [callLogs, setCallLogs] = React.useState(0);
  const [messageLogs, setMessageLogs] = React.useState(0);
  const [locationLogs, setLocationLogs] = React.useState(0);

  // Call Log Chart data
  const [callLogChartData, setCallLogChartData] = React.useState<any>({
    labels: ["---"],
    datasets: [
      {
        label: "Call Logs",
        data: [],
        backgroundColor: [],
        borderColor: [],
      },
    ],
  });

  // Message Log Chart data
  const [messageLogChartData, setMessageLogChartData] = React.useState<any>({
    labels: ["---"],
    datasets: [
      {
        label: "Message Logs",
        data: [],
        backgroundColor: [],
        borderColor: [],
      },
    ],
  });

  // Location Log Chart data
  const [locationLogChartData, setLocationLogChartData] = React.useState<any>({
    labels: ["---"],
    datasets: [
      {
        label: "Location Logs",
        data: [],
        backgroundColor: [],
        borderColor: [],
      },
    ],
  });

  // Load Logs
  async function loadDevices() {
    // Load logs from database
    setLoadingLogs(true);

    // Load device count
    const logsRef = collection(database, FirestoreUtil.SOSLogs);
    // const logsSnapshot = await getDocs(query(logsRef, orderBy("triggeredAt", "desc"), limit(10)));
    const logsSnapshot = await getDocs(
      query(logsRef, orderBy("triggeredAt", "desc"))
    );
    // const logsSnapshot = await getDocs(query(logsRef));

    // Set logs
    setLogDocs(logsSnapshot.docs);

    // Set first SOS date
    const firstSOSDate = logsSnapshot.docs[0].data().triggeredAt.toDate();
    const lastSOSDate = logsSnapshot.docs[logsSnapshot.docs.length - 1]
      .data()
      .triggeredAt.toDate();
    console.log("First SOS: " + firstSOSDate);
    console.log("Last SOS: " + lastSOSDate);

    // Set call logs
    // Get all logs where 'emergencyNumber' is not null or empty or undefined
    const callLogs = logsSnapshot.docs.filter((log) => {
      const emergencyNumber = log.data().emergencyNumber;
      return (
        emergencyNumber !== null &&
        emergencyNumber !== "" &&
        emergencyNumber !== undefined
      );
    }).length;
    setCallLogs(callLogs);

    // Set message logs
    // Get all logs where 'contacts' is not empty
    const messageLogs = logsSnapshot.docs.filter((log) => {
      const contacts = log.data().contacts;
      if (contacts === null || contacts === undefined) return false;
      return contacts.length > 0;
    }).length;
    setMessageLogs(messageLogs);

    // Set location logs
    // Get all logs where 'location' coordinates are not (0, 0)
    const locationLogs = logsSnapshot.docs.filter((log) => {
      const location = log.data().location;
      return location.latitude !== 0 && location.longitude !== 0;
    }).length;
    setLocationLogs(locationLogs);

    setLoadingLogs(false);

    // Generate message chart data
    const labels: string[] = ["Message Logs", "Others"];
    const data: number[] = [
      messageLogs,
      logsSnapshot.docs.length - messageLogs,
    ];
    const colors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[0], 0.5),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 0.5),
    ];
    const borderColors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[0], 1),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 1),
    ];

    setMessageLogChartData({
      labels: labels,
      datasets: [
        {
          label: "SOS Logs",
          data: data,
          backgroundColor: colors,
          borderColor: borderColors,
        },
      ],
    });

    // Generate call chart data
    const callLabels: string[] = ["Call Logs", "Others"];
    const callData: number[] = [callLogs, logsSnapshot.docs.length - callLogs];
    const callColors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[1], 0.5),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 0.5),
    ];
    const callBorderColors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[1], 1),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 1),
    ];

    setCallLogChartData({
      labels: callLabels,
      datasets: [
        {
          label: "SOS Logs",
          data: callData,
          backgroundColor: callColors,
          borderColor: callBorderColors,
        },
      ],
    });

    // Generate location chart data
    const locationLabels: string[] = ["Location Sent", "Not Sent"];
    const locationData: number[] = [
      locationLogs,
      logsSnapshot.docs.length - locationLogs,
    ];
    const locationColors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[2], 0.5),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 0.5),
    ];
    const locationBorderColors: string[] = [
      StatsUtil.hexToRGBA(StatsUtil.colors[2], 1),
      StatsUtil.hexToRGBA(StatsUtil.colors[6], 1),
    ];

    setLocationLogChartData({
      labels: locationLabels,
      datasets: [
        {
          label: "SOS Logs",
          data: locationData,
          backgroundColor: locationColors,
          borderColor: locationBorderColors,
        },
      ],
    });
  }

  return (
    <>
      <div className="StatsPage__GridItem">
        <h2>{t('SOS Type')}</h2>
        <p>{t('SOS Triggers')}: {logDocs.length}</p>
        <p>{t('SOS Type Message')}: {messageLogs}</p>
        <div className="StatsPage__GridItem__Chart">
          <Pie data={messageLogChartData} />
        </div>
        <div className="StatsPage__GridItem__Actions">
          <Button loading={loadingLogs} onClick={loadDevices}>
            {t('Load Stats')}
          </Button>
        </div>
      </div>
      <div className="StatsPage__GridItem">
        <h2>{t('SOS Calls')}</h2>
        <p>{t('Total Triggers')}: {logDocs.length}</p>
        <p>{t('SOS Type Call')}: {callLogs}</p>
        <div className="StatsPage__GridItem__Chart">
          <Pie data={callLogChartData} />
        </div>
      </div>
      <div className="StatsPage__GridItem">
        <h2>{t('SOS Location')}</h2>
        <p>{t('Total Triggers')}: {logDocs.length}</p>
        <p>{t('Location Sent')}: {locationLogs}</p>
        <div className="StatsPage__GridItem__Chart">
          <Pie data={locationLogChartData} />
        </div>
      </div>
    </>
  );
}
