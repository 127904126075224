import "./TitleBar.css";

// Icons
import {
  HiChartPie,
  HiDeviceMobile,
  HiDocumentText,
  HiDownload,
  HiInformationCircle,
  HiKey,
  HiLogout,
  HiMenu,
  HiPlus,
  HiQrcode,
  HiX,
  HiStar,
} from "react-icons/hi";
import { FaUsers } from "react-icons/fa";

// Images
import logo from "../../assets/images/transparent-app_icon.png";
import { auth } from "../..";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import React from "react";
import Spacer from "../Spacer/Spacer";
import TranslationLoadButton from "./TranslationLoadButton";
import KeyGeneratorDialog from "../../dialogs/KeyGeneratorDialog/KeyGeneratorDialog";
import { AppUtil } from "../../utils/AppUtil";
import ITranslationData, {
  ICategoryModel,
} from "../../models/TranslationModel";
import { useDispatch, useSelector } from "react-redux";
import { User } from "@firebase/auth";
import { setPage } from "../../slices/pageSlice";
import QRGenerationDialog from "../../dialogs/QRGenerationDialog/QRGenerationDialog";
import { useUserAuthorization } from "../../context/UserAuthorizationContext";
import { IoLogoFirebase } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { MdAccountCircle } from "react-icons/md";

type Props = {
  user: User | null;
};

/**
 * The title bar for the application.
 *
 * @returns {JSX.Element}
 */
export default function TitleBar(props: Props): JSX.Element {
  const dispatch = useDispatch();

  // Get current page
  const page = useSelector((state: any) => state.pageSlice.page);

  // Dropdown Menu
  const [dropdown, setDropdown] = React.useState(false);

  // Dialogs
  const [keyGeneratorDialog, setKeyGeneratorDialog] = React.useState(false);
  const [qrGenerationDialog, setQrGenerationDialog] = React.useState(false);

  // Export translation data
  const categories: ICategoryModel[] = useSelector(
    (state: any) => state.translationSlice.categories
  );
  const translationDataList: ITranslationData[] = useSelector(
    (state: any) => state.translationSlice.translations
  );
  const exportData = () => {
    AppUtil.generateXmlFiles(categories, translationDataList);
  };
  const { userAuthorization } = useUserAuthorization();
  const { t } = useTranslation();
  return (
    <div className="TitleBar">
      {qrGenerationDialog && (
        <QRGenerationDialog
          onClose={() => {
            setQrGenerationDialog(false);
          }}
        />
      )}

      {/* Info */}
      <div className="TitleBar__Info">
        {/* Window Controls - Disabled */}
        {/* <div className="WindowControls">
          <button
            className="Close"
            onClick={() => {
              window.close();
            }}
          ></button>
          <button
            className="Minimize"
            onClick={() => {
              window.winApi.onMinimizeButtonClick();
            }}
          ></button>
          <button
            className="Maximize"
            onClick={() => {
              window.winApi.onMaximizeButtonClick();
            }}
          ></button>
        </div>

        <Spacer width={16} /> */}

        {/* Title */}
        <button
          className="TitleBar__Info__Title"
          onClick={() => {
            dispatch(setPage("home"));
          }}
        >
          <img src={logo} alt="Senifone Admin Tools" />
          <span>{t("Admin Tools")}</span>
        </button>

        {/* Navigation */}
        {props.user && (
          <div className="TitleBar__Nav">
            <ul>
              <li>
                <button
                  className={`
                    ${page === "devices" && "active "}
                       ${!userAuthorization.DEVICES_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.DEVICES_VIEW) {
                      return;
                    }
                    dispatch(setPage("devices"));
                  }}
                >
                  <HiDeviceMobile />
                  <span>{t("Devices")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                    ${page === "lease" && "active "}
                       ${!userAuthorization.LEASES_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.LEASES_VIEW) {
                      return;
                    }
                    dispatch(setPage("lease"));
                  }}
                >
                  <HiStar />
                  <span>{t("Lease")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                   ${page === "translator" && "active "}
                      ${!userAuthorization.TRANSLATION_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.TRANSLATION_VIEW) {
                      return;
                    }
                    dispatch(setPage("translator"));
                  }}
                >
                  <HiDocumentText />
                  <span>{t("Translator")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                 ${page === "key-generator" && "active "}
                    ${!userAuthorization.RESETKEYS_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.RESETKEYS_VIEW) {
                      return;
                    }
                    dispatch(setPage("key-generator"));
                  }}
                >
                  <HiKey />
                  <span>{t("Reset Keys")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                   ${page === "sos-usage" && "active "}
                      ${!userAuthorization.SOSUSAGE_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.SOSUSAGE_VIEW) {
                      return;
                    }
                    dispatch(setPage("sos-usage"));
                  }}
                >
                  <HiInformationCircle />
                  <span>{t("SOS Usage")}</span>
                </button>
              </li>

              {/* New Additions */}
              <li>
                <button
                  className={`
                   ${page === "remote-control" && "active "}
                      ${!userAuthorization.REMOTE_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.REMOTE_VIEW) {
                      return;
                    }
                    dispatch(setPage("remote-control"));
                  }}
                >
                  <HiDeviceMobile />
                  <span>{t("Remote")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                   ${page === "stats" && "active "}
                      ${!userAuthorization.STATS_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.STATS_VIEW) {
                      return;
                    }
                    dispatch(setPage("stats"));
                  }}
                >
                  <HiChartPie />
                  <span>{t("Stats")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                   ${page === "account" && "active "}`
                    }
                  onClick={() => {
                    dispatch(setPage("account"));
                  }}
                >
                  <MdAccountCircle />
                  <span>{t("Account")}</span>
                </button>
              </li>
              {/* End - New Additions */}

              <li>
                <button
                  className={`
                  ${page === "staff-members" && "active "}
                     ${!userAuthorization.STAFFMEMBERS_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.STAFFMEMBERS_VIEW) {
                      return;
                    }
                    dispatch(setPage("staff-members"));
                  }}
                  style={
                    !userAuthorization.STAFFMEMBERS_VIEW
                      ? { display: "none" }
                      : {}
                  }
                >
                  <FaUsers />
                  <span>{t("Staff Members")}</span>
                </button>
              </li>
              <li>
                <button
                  className={`
                  ${page === "track-analytics" && "active "}
                     ${!userAuthorization.ANALYTICS_VIEW && " disabled"}`}
                  onClick={() => {
                    if (!userAuthorization.ANALYTICS_VIEW) {
                      return;
                    }
                    dispatch(setPage("track-analytics"));
                  }}
                  style={
                    !userAuthorization.ANALYTICS_VIEW ? { display: "none" } : {}
                  }
                >
                  <IoLogoFirebase />
                  <span> {t("Staff Logs")}</span>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Actions */}
      {props.user && (
        <div className="TitleBar__Actions">
          <ul>
            {/* Translator Options */}
            {page === "translator" && (
              <>
                <li className="TranslationSaveButton">
                  <TranslationLoadButton />
                </li>
                <li>
                  <Button
                    onClick={() => {
                      exportData();
                    }}
                  >
                    <HiDownload />
                    {t("Download")}
                  </Button>
                </li>
              </>
            )}

            {/* KeyGenerator Options */}
            {page === "key-generator" && (
              <>
                {keyGeneratorDialog && (
                  <KeyGeneratorDialog
                    onClose={() => {
                      setKeyGeneratorDialog(false);
                    }}
                  />
                )}
                <li>
                  <Button
                    onClick={() => {
                      setKeyGeneratorDialog(true);
                    }}
                  >
                    <HiPlus />
                    {t("Generate Key")}
                  </Button>
                </li>
              </>
            )}

            {/* Admin Dropdown Menu */}
            <li className="UserDropdownContainer">
              {dropdown && (
                <div className="UserDropdown">
                  <h3>{t("Admin Options")}</h3>
                  <Spacer height={16} />
                  <ul>
                    {userAuthorization.QR_GENERATE && (
                      <li>
                        <Button
                          onClick={() => {
                            setDropdown(false);
                            setQrGenerationDialog(true);
                          }}
                        >
                          <HiQrcode />
                          {t("QR Code")}
                        </Button>
                      </li>
                    )}
                    <li>
                      <Button
                        onClick={() => {
                          auth.signOut();
                        }}
                      >
                        <HiLogout />
                        {t("Log Out")}
                      </Button>
                    </li>
                  </ul>
                </div>
              )}

              {/* User Dropdown Icon */}
              <IconButton
                onClick={() => {
                  setDropdown(!dropdown);
                }}
              >
                {dropdown ? <HiX /> : <HiMenu />}
              </IconButton>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
