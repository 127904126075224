import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./TextField.css";
import { useState } from "react";

type Props = {
  id?: string;
  className?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  prefix?: React.ReactNode;
  style?: React.CSSProperties;
};

/**
 * [TextField](./README.md) component, a simple text input field with some extra features.
 *
 * @param props
 * @returns {JSX.Element}
 */
export default function TextField(props: Props): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // ClassName
  const className =
    props.className ??
    "TextField" +
      (props.fullWidth ? " TextField__fullWidth" : "") +
      (props.prefix ? " TextField__withPrefix" : "");

  // TextField type
  const type = props.type ?? "text";

  return (
    <div id={props.id} className={className} style={props.style}>
      {props.prefix && <div className="TextField__Prefix">{props.prefix}</div>}

      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
      />
      {type === "password" && (
        <div className="show-Password" onClick={handleShowPassword}>
          {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
        </div>
      )}
    </div>
  );
}
