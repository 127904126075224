import { createSlice } from "@reduxjs/toolkit";
import ITranslationData, { ICategoryModel, ITranslationModel } from "../models/TranslationModel";

export type TranslationSliceData = {
    categories: ICategoryModel[];
    translations: ITranslationData[];
    selectedCategory?: string;

    isLoading: boolean; // Loading from storage or file
    isSaving: boolean; // Saving to storage
};

const initialState: TranslationSliceData = {
    categories: [],
    translations: [],
    selectedCategory: undefined,
    isLoading: false,
    isSaving: false,
};

export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateTranslationModel: (state, action) => {
            // Get {dataIndex, translationModelKey, value} from the action payload
            const { dataIndex, translationModelKey, value } = action.payload;

            // Get the translation model
            const translationModel = state.translations[dataIndex].translations.find((translationModel: ITranslationModel) => translationModel.key === translationModelKey);

            if (!translationModel) return;

            // Update the value
            translationModel.value = value;

            // Update the translations
            state.translations = state.translations.map((translationData: ITranslationData) => {
                if (translationData === state.translations[dataIndex]) {
                    return state.translations[dataIndex];
                }
                return translationData;
            });
        },
        setIsSaving: (state, action) => {
            state.isSaving = action.payload;
        }
    }
});

export const {
    setCategories,
    setTranslations,
    setSelectedCategory,
    setLoading,
    updateTranslationModel,
    setIsSaving,
} = translationSlice.actions;