import moment from "moment";

const PaymentTable = ({ paymentDetails }: any) => {
  return (
    <div>
      <div className="LeaseTableContainer">
        <div className="LeaseTableContentContainer">
          <table className="LeaseTable">
            <thead>
              <tr>
                <th>Id</th>
                <th>Mode</th>
                <th>Created At</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Method</th>
                <th>Status</th>
                <th>Customer Id</th>
                <th>Subscription Id</th>
                <th>Sequence Type</th>
                <th>Consumer Name</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.map((el: any) => (
                <tr className="tableData">
                  <td>{el?.id}</td>
                  <td>{el?.mode}</td>
                  <td>
                    {moment(new Date(el?.createdAt))?.format("HH:mm, DD/MM/YY")}
                  </td>
                  <td>
                    {el?.amount?.value} {el?.amount?.currency}
                  </td>
                  <td>{el?.description}</td>
                  <td>{el?.method}</td>
                  <td>{el?.status}</td>
                  <td>{el?.customerId}</td>
                  <td>{el?.subscriptionId}</td>
                  <td>{el?.sequenceType}</td>
                  <td>{el?.details?.consumerName}</td>
                  <td>{el?.details?.dueDate}</td>
                </tr>
              ))}
            </tbody>
            <div className="LeaseTableFooter" />
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentTable;
