enum RemoteLogType {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

// Device Log Interface
// This is used to store the logs for all the actions that are performed on the device
export default interface IRemoteLog {
  id: string;
  title: string;
  message: string;
  timestamp: Date;
  type: RemoteLogType;
}

export { RemoteLogType };
