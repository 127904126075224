import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React from "react";
import { HiFolderAdd, HiKey, HiUser, HiX } from "react-icons/hi";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import TextField from "../../components/TextField/TextField";
import { database } from "../..";
import "./KeyGeneratorDialog.css";
import {
  RESOURCE,
  useUserAuthorization,
} from "../../context/UserAuthorizationContext";
import { trackAnalytics } from "../../utils/TrackUtils";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};

/**
 * Key Generator Dialog for generating reset keys for users who have lost their PIN for their device Restrictive mode.
 *
 * @param props
 * @returns {JSX.Element}
 */
export default function KeyGeneratorDialog(props: Props): JSX.Element {
  const { user } = useUserAuthorization();
  // State
  const [userName, setUserName] = React.useState("");
  const [userCode, setUserCode] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [recoveryCode, setRecoveryCode] = React.useState("");
  const [resetKey, setResetKey] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [generating, setGenerating] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="KeyGeneratorOverlay" />

      <div className="KeyGeneratorContainer">
        <div className="generate-key-dialog-content">
          <h3>{t("Generate Reset Key")}</h3>
          <p>
            {t('Enter the Recovery Code for which you want to generate reset code')}.
          </p>
          <Spacer height={24} />
          <TextField
            prefix={<HiUser />}
            placeholder="User ID (If available)..."
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
            fullWidth
          />
          <Spacer height={8} />
          <TextField
            prefix={<HiUser />}
            placeholder="User Name (If available)..."
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            fullWidth
          />
          <Spacer height={8} />
          <TextField
            prefix={<HiUser />}
            placeholder="User Code (If available)..."
            value={userCode}
            onChange={(e) => {
              setUserCode(e.target.value);
            }}
            fullWidth
          />
          <Spacer height={8} />
          <TextField
            prefix={<HiKey />}
            placeholder="Recovery Code..."
            value={recoveryCode}
            onChange={(e) => {
              setRecoveryCode(e.target.value);
            }}
            fullWidth
          />
          <Spacer height={24} />

          {/* Error Message */}
          {errorMessage.length > 0 && <Alert>{errorMessage}</Alert>}

          {/* Reset Key */}
          {resetKey.length > 0 && (
            <div className="generate-key-dialog-key">
              <b>{t('Reset Key')}</b>
              <p>{resetKey}</p>
            </div>
          )}

          {/* Actions */}
          <div className="generate-key-dialog-buttons">
            {/* Generate Key */}
            <Button
              loading={generating}
              onClick={async () => {
                // 1. Check if recovery code is valid
                if (recoveryCode.length === 0) {
                  setErrorMessage("Recovery code cannot be empty.");
                  return;
                }

                // 2. Convert recovery code to number
                const recoveryCodeNumber = parseInt(recoveryCode);
                if (isNaN(recoveryCodeNumber)) {
                  setErrorMessage("Recovery code must be a number.");
                  return;
                }

                // 3. Subtract [975312468] from the recovery code
                const resetKeyNumber = recoveryCodeNumber - 975312468;
                // 4. Divide the result by 2
                const resetKeyNumber2 = resetKeyNumber / 2;
                // 5. Convert the result to string
                const resetKeyString = resetKeyNumber2.toString();
                // 6. Take the first 4 characters
                const resetKeyString2 = resetKeyString.substring(0, 4);
                // 7. Convert the result to number
                const resetKeyNumber3 = parseInt(resetKeyString2);
                // 8. Add 975312468 to the result
                const resetKeyNumber4 = resetKeyNumber3 + 975312468;
                // 9. Convert the result to string
                const resetKeyString3 = resetKeyNumber4.toString();

                // 9.1 Set generating
                setGenerating(true);

                // 10. Add generated key to clipboard
                navigator.clipboard.writeText(resetKeyString3);

                // 11. Add generated key to 'Firebase' database
                // TODO:: Handle saving to database
                const obj = {
                  userID: userId,
                  userName: userName,
                  userCode: userCode,
                  recoveryCode: recoveryCode,
                  resetKey: resetKeyString3,
                  generatedAt: serverTimestamp(),
                };
                try {
                  await addDoc(collection(database, "ResetKeys"), obj);

                  // 12. Set generating
                  setErrorMessage("");
                  setResetKey(resetKeyString3);
                  setGenerating(false);

                  trackAnalytics(RESOURCE.KEY_GENERATE, obj, true);
                } catch (error) {
                  trackAnalytics(RESOURCE.KEY_GENERATE, error, false);
                }
              }}
            >
              <HiFolderAdd />
              {t('Generate')}
            </Button>

            {/* Close Button */}
            <Button
              onClick={() => {
                props.onClose();
              }}
            >
              <HiX />
              {t('Close')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
