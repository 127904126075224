import { ButtonType } from "../Button/Button";
import CircularLoading from "../CircularLoading/CircularLoading";
import "./IconButton.css";

type Props = {
    children: React.ReactNode;
    type?: ButtonType;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
}

export default function IconButton(props: Props) {
    const type = props.type ?? ButtonType.Default;

    return (
        <button
            className={"IconButton " + type}
            onClick={props.onClick}
            disabled={props.disabled || props.loading}
        >
            {props.loading ? <CircularLoading size={24} strokeWidth={2} /> : props.children}
        </button>
    );
}