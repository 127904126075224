import "./CircularLoading.css";

interface ICircularLoadingProps {
    size?: number;
    color?: string;
    strokeWidth?: number;
}

export default function CircularLoading(props: ICircularLoadingProps) {
    const finalSize = props.size || 50;
    const finalColor = props.color || "#F9572B";
    const finalStrokeWidth = props.strokeWidth || 5;

    return (
        <div className="lds-ring" style={{
            width: finalSize,
            height: finalSize,
        }}>
            <div style={{
                margin: finalStrokeWidth,
                border: `${finalStrokeWidth}px solid ${finalColor}`,
                width: finalSize - (finalStrokeWidth * 2),
                height: finalSize - (finalStrokeWidth * 2),
                borderColor: `${finalColor} transparent transparent transparent`,
            }}></div>
            <div style={{
                margin: finalStrokeWidth,
                border: `${finalStrokeWidth}px solid ${finalColor}`,
                width: finalSize - (finalStrokeWidth * 2),
                height: finalSize - (finalStrokeWidth * 2),
                borderColor: `${finalColor} transparent transparent transparent`,
            }}></div>
            <div style={{
                margin: finalStrokeWidth,
                border: `${finalStrokeWidth}px solid ${finalColor}`,
                width: finalSize - (finalStrokeWidth * 2),
                height: finalSize - (finalStrokeWidth * 2),
                borderColor: `${finalColor} transparent transparent transparent`,
            }}></div>
            <div style={{
                margin: finalStrokeWidth,
                border: `${finalStrokeWidth}px solid ${finalColor}`,
                width: finalSize - (finalStrokeWidth * 2),
                height: finalSize - (finalStrokeWidth * 2),
                borderColor: `${finalColor} transparent transparent transparent`,
            }}></div>
        </div>
    );
}