import React from "react";
import { useSelector } from "react-redux";
import DevicesPage from "./pages/Devices/DevicesPage";
import HomePage from "./pages/Home/HomePage";
import KeyGeneratorPage from "./pages/KeyGenerator/KeyGeneratorPage";
import SOSUsagePage from "./pages/SOSUsage/SOSUsagePage";
import TranslatorPage from "./pages/Translator/TranslatorPage";
import RemoteControlPage from "./pages/RemoteControl/RemoteControlPage";
import UnknownPage from "./pages/Unknown/UnknownPage";

import VerifyOTP from "./pages/VerifyOTP/VerifyOTP";
import { User } from "firebase/auth";
import MFAEnrollment from "./pages/MFAEnrollment/MFAEnrollment";
import StaffMembersPage from "./pages/StaffMembers/StaffMembers";
import StaffLogsPage from "./pages/StaffLogs/StaffLogs";
import StatsPage from "./pages/Stats/StatsPage";
import LeasePage from "./pages/Lease/LeasePage";
import AccountPage from "./pages/Account";

export default function PageRouter({ user }: { user: User }) {
  const page = useSelector((state: any) => state.pageSlice.page);
  switch (page) {
    case "home":
      return <HomePage user={user} />;
    case "translator":
      return <TranslatorPage />;
    case "devices":
      return <DevicesPage />;
    case "lease":
      return <LeasePage />;
    case "staff-members":
      return <StaffMembersPage />;
    case "track-analytics":
      return <StaffLogsPage />;
    case "key-generator":
      return <KeyGeneratorPage />;
    case "sos-usage":
      return <SOSUsagePage />;
    case "remote-control":
      return <RemoteControlPage />;
    case "stats":
      return <StatsPage />;
    case "account":
      return <AccountPage />;
    case "verify-otp":
      return <VerifyOTP user={user} />;
    case "mfa-enrollment":
      return <MFAEnrollment user={user} />;
    default:
      return <UnknownPage />;
  }
}
