//  @ts-nocheck
import http from "../httpInstance";

class QRCodeService {
  /**
   * @description - get QR Code.
   * @returns - Return encoded string.
   */

  async generateQrCode({ policy_json, policy_name }) {
    try {
      const records = await http.post("generate-qrCode", {
        policy_json,
        policy_name,
      });
      return records;
    } catch (error) {
      throw error;
    }
  }

  async getQrCodes() {
    try {
      const records = await http.get("qrCode");
      return records.data;
    } catch (error) {
      throw error;
    }
  }

  async addNewQrCode(data) {
    try {
      const res = await http.post("qrCode", data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new QRCodeService();
