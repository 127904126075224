import { DocumentData } from "firebase/firestore";
import { useState } from "react";
import { HiEye } from "react-icons/hi";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import StaffMemberDetails from "../../dialogs/StaffMemberDetails/StaffMemberDetails";
import { useTranslation } from "react-i18next";

type Props = {
  member: DocumentData;
  index: number;
  authorization: any;
  staffMembers: any;
  setStaffMembers: any;
};

export default function StaffMembersRow(props: Props) {
  const { member, index, staffMembers, setStaffMembers } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const hideButton = member?.role === "ADMIN";
  const { t } = useTranslation();
  return (
    <>
      {openModal && (
        <StaffMemberDetails
          onClose={() => {
            setOpenModal(false);
          }}
          member={member}
          staffMembers={staffMembers}
          setStaffMembers={setStaffMembers}
        />
      )}
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className={"online"}>{member.uid}</span>
        </td>
        <td>{member.displayName || "_"}</td>
        <td>{member.email}</td>
        <td className="DeviceRowActions">
          <Spacer width={10} />
          {!hideButton && (
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <HiEye />
              {t('View Details')}
            </Button>
          )}
        </td>
      </tr>
    </>
  );
}
