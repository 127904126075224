export interface IStatsDevice {
  id: string;
  name: string;
  model: string;
  color: string;
  count: number;
}

export default abstract class StatsUtil {
  static hexToRGBA(color: string, alpha: number) {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    console.log(r, g, b);
    return `rgba(${r},${g},${b},${alpha})`;
  }
  static colors = [
    "#fc5e03",
    "#00b35f",
    "#ed0077",
    "#624aff",
    "#fff875",
    "#00e6e2",
    "#d1d1d1",
  ];
}
