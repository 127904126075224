import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React from "react";
import { database } from "../../..";
import FirestoreUtil from "../../../utils/FirestoreUtil";
import Button from "../../../components/Button/Button";
import StatsUtil, { IStatsDevice } from "../StatsUtil";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StatsDevices() {
  const [loadingDevices, setLoadingDevices] = React.useState(false); // Loading devices from database
  const [totalDevices, setTotalDevices] = React.useState(0);
  const [deviceModels, setDeviceModels] = React.useState<IStatsDevice[]>([]);

  // Chart data
  const [chartData, setChartData] = React.useState<any>({
    labels: ["Unknown Device"],
    datasets: [
      {
        label: "Total Devices",
        data: [],
        backgroundColor: [],
        borderColor: [],
      },
    ],
  });

  // Load Devices
  async function loadDevices() {
    // Load devices from database
    setLoadingDevices(true);
    setTotalDevices(0);

    // Load device count
    const devicesRef = collection(database, FirestoreUtil.UserData);
    // const devicesSnapshot = await getDocs(query(devicesRef, orderBy("registrationDate", "desc"), limit(10)));
    const devicesSnapshot = await getDocs(query(devicesRef));

    // Set device count
    setTotalDevices(devicesSnapshot.size);

    // Load device models
    const deviceModels: IStatsDevice[] = [];
    devicesSnapshot.forEach((device) => {
      const deviceModel = device.data().deviceModel;
      const deviceModelIndex = deviceModels.findIndex(
        (deviceModelItem) => deviceModelItem.model === deviceModel
      );

      if (deviceModelIndex === -1) {
        let deviceName = "Unknown Device";
        if (
          deviceModel === "M2006C3LG" ||
          deviceModel === "M2006C3LI" ||
          deviceModel === "M2006C3LC" ||
          deviceModel === "M2004C3L"
        ) {
          deviceName = "Redmi 9a";
        } else if (
          deviceModel === "5030F" ||
          deviceModel === "5030D_EEA" ||
          deviceModel === "5030U" ||
          deviceModel === "5030F_EEA" ||
          deviceModel === "5030D" ||
          deviceModel === "5030E" ||
          deviceModel === "5030A" ||
          deviceModel === "5030E_TCL" ||
          deviceModel === "5030I"
        ) {
          deviceName = "Alcatel 1SE";
        } else if (deviceModel === "5033D") {
          deviceName = "Alcatel 1";
        } else if (deviceModel === "M2006C3LVG") {
          deviceName = "Redmi 9AT";
        }

        deviceModels.push({
          id: deviceModels.length.toString(),
          name: deviceName,
          model: deviceModel,
          color:
            StatsUtil.colors[deviceModels.length % StatsUtil.colors.length],
          count: 1,
        });
      } else {
        deviceModels[deviceModelIndex].count++;
      }
    });

    // Merge device models by 'name'
    const deviceModelsMerged: IStatsDevice[] = [];
    deviceModels.forEach((deviceModel) => {
      const deviceModelIndex = deviceModelsMerged.findIndex(
        (deviceModelItem) => deviceModelItem.name === deviceModel.name
      );

      if (deviceModelIndex === -1) {
        deviceModelsMerged.push(deviceModel);
      } else {
        deviceModelsMerged[deviceModelIndex].count += deviceModel.count;
      }
    });

    // Set device models
    setDeviceModels(deviceModelsMerged);
    setLoadingDevices(false);

    const labels: string[] = [];
    const data: number[] = [];
    const colors: string[] = [];
    const borderColors: string[] = [];

    deviceModelsMerged.forEach((deviceModel) => {
      // Convert 'hex' to 'rgba'
      const color = StatsUtil.hexToRGBA(deviceModel.color, 0.5);
      const borderColor = StatsUtil.hexToRGBA(deviceModel.color, 1);

      labels.push(deviceModel.name);
      data.push(deviceModel.count);
      colors.push(color);
      borderColors.push(borderColor);
    });

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Devices",
          data: data,
          backgroundColor: colors,
          borderColor: borderColors,
        },
      ],
    });
  }
  const { t } = useTranslation();
  return (
    <div className="StatsPage__GridItem">
      <h2>{t('Devices')}</h2>
      <p>{t('Total Devices')}: {totalDevices}</p>
      <div className="StatsPage__GridItem__Chart">
        <Pie data={chartData} />
      </div>
      {/* <div className="StatsPage__GridItem__List">
                {deviceModels.map((deviceModel) => (
                    <p key={deviceModel.id}>
                        <span className="color" style={{ backgroundColor: deviceModel.color }}></span>
                        {deviceModel.name} - {deviceModel.count}
                    </p>
                ))}
            </div> */}
      <div className="StatsPage__GridItem__Actions">
        <Button loading={loadingDevices} onClick={loadDevices}>
          {t('Load Stats')}
        </Button>
      </div>
    </div>
  );
}
