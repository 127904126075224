// @ts-nocheck
import { Button, FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import Spacer from "../../components/Spacer/Spacer";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "../../components/TextField/TextField";
import { Stack } from "@mui/material";
import { useUserAuthorization } from "../../context/UserAuthorizationContext";
import { doc, setDoc } from "firebase/firestore";
import { database } from "../..";
import CircularLoading from "../../components/CircularLoading/CircularLoading";

const AccountGeneralUserInfo = ({ userData }: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuthorization();
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: userData?.displayName,
      address: userData?.address,
      country: userData?.country,
      state: userData?.state,
      city: userData?.city,
      zipCode: userData?.zipCode,
    },
  });

  const onSubmit = async (data: any) => {
    const { name: displayName, address, country, state, city, zipCode } = data;
    try {
      setLoading(true);
      await setDoc(
        doc(database, "Users", user.uid),
        {
          displayName,
          address,
          country,
          state,
          city,
          zipCode,
        },
        { merge: true }
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="general-page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12} justifyContent={"center"}>
              <div className="TextField__Title">{t("Name")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t("Name is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder={t("Enter your name")}
                    />
                  </>
                )}
              />
              <Stack direction={"row"} justifyContent={"flex-start"}>
                {errors.name && (
                  <FormHelperText error>{errors.name.message}</FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} xs={1} />
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12}>
              <div className="TextField__Title">{t("Zip/Code")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="zipCode"
                control={control}
                rules={{
                  required: t("Zip/Code is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="number"
                      placeholder={t("Enter your Zip/Code")}
                    />
                  </>
                )}
              />
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                width={"320px"}
              >
                {errors.zipCode && (
                  <FormHelperText error sx={{ mr: "55px" }}>
                    {errors.zipCode.message}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            xs={1}
            sx={{ height: { xs: "", md: "100px" } }}
          />
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12} justifyContent={"center"}>
              <div className="TextField__Title">{t("Address")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: t("Address is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder={t("Enter your address")}
                    />
                  </>
                )}
              />
              <Stack direction={"row"} justifyContent={"flex-start"}>
                {errors.address && (
                  <FormHelperText error>
                    {errors.address.message}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} xs={1} />
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12}>
              <div className="TextField__Title">{t("Country")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: t("Country is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder={t("Enter your country")}
                    />
                  </>
                )}
              />
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                width={"320px"}
              >
                {errors.country && (
                  <FormHelperText error sx={{ mr: "55px" }}>
                    {errors.country.message}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            xs={1}
            sx={{ height: { xs: "", md: "100px" } }}
          />
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12} justifyContent={"center"}>
              <div className="TextField__Title">{t("State/Region")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: t("State/Region is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder={t("Enter your State/Region")}
                    />
                  </>
                )}
              />
              <Stack direction={"row"} justifyContent={"flex-start"}>
                {errors.state && (
                  <FormHelperText error>{errors.state.message}</FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} xs={1} />
          <Grid container lg={4} md={4} xs={12} alignItems={"center"}>
            <Grid item lg={4} md={4} xs={12}>
              <div className="TextField__Title">{t("City")}:</div>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: t("City is required"),
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder={t("Enter your City")}
                    />
                  </>
                )}
              />
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                width={"320px"}
              >
                {errors.city && (
                  <FormHelperText error sx={{ mr: "55px" }}>
                    {errors.city.message}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            xs={1}
            sx={{ height: { xs: "", md: "100px" } }}
          />
        </Grid>

        <div
          className="AccountButton general-page-button"
          style={{ marginTop: 20 }}
        >
          <Button variant="outlined" type="submit">
            {loading ? (
              <CircularLoading size={25} strokeWidth={3} />
            ) : (
              t("Save")
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountGeneralUserInfo;
