import {
  DocumentData,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React from "react";
import ActiveRoomRow from "./ActiveRoomRow";
import FirestoreUtil from "../../../utils/FirestoreUtil";
import { database } from "../../..";
import CircularLoading from "../../../components/CircularLoading/CircularLoading";
import { Room } from "livekit-client";
import { useTranslation } from "react-i18next";

type Props = {
  connectedRoom: Room | null;
  onClickConnect: (request: DocumentData) => void;
  disconnect: () => void;
};

export default function ActiveRoomsComp(props: Props) {
  // State
  const [remoteRequests, setRemoteRequests] = React.useState<DocumentData[]>(
    []
  );
  const [loading, setLoading] = React.useState<boolean>(true);

  // Methods
  // Listen Remote Requests
  const listenRemoteRequests = async () => {
    setLoading(true);
    const q = query(
      collection(database, FirestoreUtil.RemoteControlRequests),
      where("status", "in", ["active", "pending"]),
      orderBy("timestamp", "desc")
    );

    onSnapshot(q, (querySnapshot) => {
      console.log("Remote Requests: " + querySnapshot.docs.length);
      setLoading(false);
      const documentData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRemoteRequests(documentData);
    });
  };

  // Disable Remote Request
  const disableRemoteRequest = async (request: DocumentData) => {
    console.log("Disable Remote Request: " + request.id);
    try {
      await setDoc(
        doc(database, FirestoreUtil.RemoteControlRequests, request.id),
        {
          status: "disabled",
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    listenRemoteRequests();
  }, []);
  const { t } = useTranslation();
  return (
    <div className="ActiveRoomsComp">
      <div className="ActiveRoomsComp__Toolbar">{t('Active Rooms')}</div>
      <div className="ActiveRoomsComp__List">
        {remoteRequests.length === 0 && (
          <div className="ActiveRoomsComp__Center">
            <p>{t('No Active Rooms')}</p>
          </div>
        )}
        {loading && (
          <div className="ActiveRoomsComp__Center">
            <CircularLoading size={32} strokeWidth={2} />
          </div>
        )}
        {remoteRequests.map((request, index) => (
          <ActiveRoomRow
            key={request.id}
            index={index}
            request={request}
            onClickConnect={() => {
              props.onClickConnect(request);
            }}
            onClickDisable={() => {
              props.disconnect();
              disableRemoteRequest(request);
            }}
          />
        ))}
      </div>
    </div>
  );
}
