import { DocumentData } from "firebase/firestore";
import moment from "moment";

type Props = {
  analytic: DocumentData;
  index: number;
};

export default function StaffLogsRow(props: Props) {
  const { analytic, index } = props;

  const isVerified = (value: number) => {
    if (value === 1) {
      return "Activated";
    } else if (value === -1) {
      return "Deactivated";
    } else {
      return "_";
    }
  };

  return (
    <>
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <span className={"online"}>{analytic?.user?.email}</span>
        </td>
        <td>{analytic.resource || "_"}</td>
        <td>
          {analytic?.outcome?.email ||
            analytic?.outcome?.resetKey ||
            isVerified(analytic?.outcome?.verified) ||
            "_"}
        </td>
        <td>
          {moment(
            new Date(
              analytic?.createdAt?.seconds * 1000 +
                analytic?.createdAt?.nanoseconds / 1000000
            )
          ).format("HH:mm, DD/MM/YY")}
        </td>
      </tr>
    </>
  );
}
