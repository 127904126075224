import React, { useState, useEffect } from "react";
import AccountGeneralUserInfo from "./AccountGeneralUserInfo";
import AccountChangePassword from "./AccountChangePassword";
import "./AccountPage.css";
import "../../dialogs/CreateUser/CreateUser.css";
import { doc, getDoc } from "firebase/firestore";
import { database } from "../..";
import { useUserAuthorization } from "../../context/UserAuthorizationContext";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import { useTranslation } from "react-i18next";

const AccountPage = () => {
  const [currentTab, setCurrentTab] = useState("general");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const { user } = useUserAuthorization();
  const {t} = useTranslation()

  const getUserData = async () => {
    setLoading(true);
    try {
      const q = doc(database, "Users", user.uid);
      const result = await getDoc(q);
      setUserData(result.data());
    } catch (err) {
      console.log("🔥💸🌏:::::>>>>error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TABS = [
    {
      value: "general",
      label: t("General"),
      icon: "",
      component: <AccountGeneralUserInfo userData={userData} />,
    },
    {
      value: "change_password",
      label: t("Change Password"),
      icon: "",
      component: <AccountChangePassword />,
    },
  ];

  return (
    <div className="AccountPage">
      <div className="TabContainer">
        <div className="tabComponent">
          {TABS.map((el) => (
            <div
              className={`tab ${el.value === currentTab && "tab-selected"}`}
              onClick={() => setCurrentTab(el.value)}
            >
              <label htmlFor={el.value}>{el.label}</label>
            </div>
          ))}
        </div>
      </div>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <div
              className="tabContent"
              key={tab.value}
              style={{ marginTop: 40 }}
            >
              {loading ? (
                <CircularLoading size={32} strokeWidth={3} />
              ) : (
                tab.component
              )}
            </div>
          )
      )}
    </div>
  );
};

export default AccountPage;
