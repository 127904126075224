import CircularLoading from "../CircularLoading/CircularLoading";
import "./Button.css";

export enum ButtonType {
  Default = "default",
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Danger = "danger",
  Warning = "warning",
  Info = "info",
  Light = "light",
  Dark = "dark",
  Link = "link",
}

type Props = {
  className?: string;
  children: React.ReactNode;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  buttonType?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
};

export default function PrimaryButton(props: Props): JSX.Element {
  const type = props.type ?? ButtonType.Default;

  return (
    <button
      className={
        "Button " + type + (props.className ? " " + props.className : "")
      }
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      type={props.buttonType}
      style={props.style}
    >
      {props.loading ? (
        <CircularLoading size={24} strokeWidth={2} />
      ) : (
        props.children
      )}
    </button>
  );
}
