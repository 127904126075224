import React, { Component } from "react";
import "./RemoteControlPage.css";
import RemoteControlToolbar from "./Components/RemoteControlToolbar";
import RemoteControlStatus from "./Data/RemoteControlStatus";
import FindDeviceComp from "./Components/FindDeviceComp";
import ActiveRoomsComp from "./Components/ActiveRoomsComp";
import { ConnectionState, DisconnectReason, LocalParticipant, LocalTrackPublication, RemoteParticipant, RemoteTrack, RemoteTrackPublication, Room, RoomEvent, Track, VideoPresets } from "livekit-client";
import { DocumentData } from "firebase/firestore";
import RemoteControlUtil from "../../utils/RemoteControlUtil";
import IRemoteLog, { RemoteLogType } from "./Data/RemoteLog";
import IInputData from "./Data/InputData";
import RemoteDeviceControls from "./Components/RemoteDeviceControls";

type Props = {

};

type State = {
  // Page State
  loading: boolean;
  errorMessage: string | null;
  logs: IRemoteLog[];

  // Remote Control State
  remoteControlStatus: RemoteControlStatus;
  remoteRoom: Room | null;
  remoteRoomTracks: RemoteTrack[];

  // Device State
  mouseDown: boolean;
  mouseX: number;
  mouseY: number;
  swipePositions: { x: number; y: number }[];
  deviceFocused: boolean;
}

export default class RemoteControlPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Page State
      loading: true,
      errorMessage: null,
      logs: [
        {
          id: "1",
          title: "Remote Control",
          message: "Page Loaded",
          timestamp: new Date(),
          type: RemoteLogType.INFO
        }
      ],

      // Remote Control State
      remoteControlStatus: RemoteControlStatus.DISCONNECTED,
      remoteRoom: null,
      remoteRoomTracks: [],

      // Device State
      mouseDown: false,
      mouseX: 0,
      mouseY: 0,
      swipePositions: [],
      deviceFocused: false,
    }

    // Bindings
    this.onClickConnect = this.onClickConnect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.setRemoteControlStatus = this.setRemoteControlStatus.bind(this);
    this.connectToRoom = this.connectToRoom.bind(this);

    // Room Events
    this.handleTrackSubscribed = this.handleTrackSubscribed.bind(this);
    this.handleTrackUnsubscribed = this.handleTrackUnsubscribed.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.handleLocalTrackUnpublished = this.handleLocalTrackUnpublished.bind(this);
    this.handleParticipantConnected = this.handleParticipantConnected.bind(this);
    this.handleConnectionStateChanged = this.handleConnectionStateChanged.bind(this);
    this.handleDataReceived = this.handleDataReceived.bind(this);

    // Device Events
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);


    // Room Events
    this.sendData = this.sendData.bind(this);
  }

  componentDidMount() {
    // Listen for keydown events
    document.addEventListener("keydown", (e) => {
      if (!this.state.deviceFocused) return;
      // Consume keydown event
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      // Get connected room
      const connectedRoom = this.state.remoteRoom;
      if (connectedRoom == null) {
        this.log("Cannot send data", "No room connected", RemoteLogType.WARNING);
        return;
      }

      // Only send data if key is not a modifier key
      if (e.key === "Shift" || e.key === "Control" || e.key === "Alt" || e.key === "Meta") return;

      // Create data
      const data: IInputData = {
        type: "keydown",
        data: JSON.stringify({
          key: e.key,
          code: e.keyCode,
          shiftKey: e.shiftKey,
          ctrlKey: e.ctrlKey,
          altKey: e.altKey,
          metaKey: e.metaKey,
        })
      };

      // Send data
      this.sendData(data);
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {

  }

  log(title: string, message: string, type: RemoteLogType = RemoteLogType.INFO) {
    const logs = this.state.logs;
    logs.push({
      id: logs.length.toString(),
      title: title,
      message: message,
      timestamp: new Date(),
      type: type
    });
    this.setState({
      logs: logs
    });

    // Scroll 'remoteControlLogs' to bottom after 1 second
    setTimeout(() => {
      const remoteControlLogs = document.getElementById("remoteControlLogs");
      if (remoteControlLogs) {
        remoteControlLogs.scrollTop = remoteControlLogs.scrollHeight;
      }
    }, 1000);
  }

  onClickConnect(document: DocumentData) {
    this.log("RemoteControl", "Request Access Token for Room: " + document.id);
    this.setRemoteControlStatus(RemoteControlStatus.CONNECTING);
    const roomName = document.id;

    // Get room access token
    RemoteControlUtil.getAccessToken(
      document,
      (token) => {
        console.log("Room Access Token: " + token);
        if (token === "") {
          this.log("RemoteControl", "Access token empty, aborting remote connection.");
          this.setErrorMessage("Error getting access token");
          this.setRemoteControlStatus(RemoteControlStatus.DISCONNECTED);
          return;
        }

        // Connect to room
        this.connectToRoom(roomName, token);
      },
      (error) => {
        this.log("RemoteControl", "Error getting access token: " + error, RemoteLogType.ERROR);
        this.setRemoteControlStatus(RemoteControlStatus.DISCONNECTED);
        this.setErrorMessage(error);
      }
    );
  }

  disconnect() {
    this.log("RemoteControl", "Disconnecting from room");
    if (this.state.remoteRoom !== null) {
      this.state.remoteRoom.disconnect();
      this.log("RemoteControl", "Disconnected from room");
    }

    this.setState({
      remoteRoom: null,
      remoteControlStatus: RemoteControlStatus.DISCONNECTED
    });
  }

  setErrorMessage(errorMessage: string | null) {
    this.setState({
      errorMessage: errorMessage
    });
  }

  setRemoteControlStatus(remoteControlStatus: RemoteControlStatus) {
    this.setState({
      remoteControlStatus: remoteControlStatus
    });
  }

  connectToRoom(roomName: string, token: string) {
    // If room is not null, disconnect from room
    if (this.state.remoteRoom !== null) {
      this.state.remoteRoom.disconnect();
      this.log("RemoteControl", "Disconnected from existing room");

      // Reset state
      this.setState({
        remoteRoom: null
      });
    }

    // Check if room is initialized
    if (this.state.remoteRoom === null) {
      this.log("RemoteControl", "Initializing Room...");

      // Initialize room
      const room = new Room({
        adaptiveStream: true,
        dynacast: true,
        disconnectOnPageLeave: true,
      });

      // Set room listeners
      room
        .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed)
        .on(RoomEvent.Disconnected, this.handleDisconnect)
        .on(RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnpublished)
        .on(RoomEvent.ParticipantConnected, this.handleParticipantConnected)
        .on(RoomEvent.ConnectionStateChanged, this.handleConnectionStateChanged)
        .on(RoomEvent.DataReceived, this.handleDataReceived);

      // Set room state
      this.setState({
        remoteRoom: room
      }, async () => {
        this.log("RemoteControl", "Room Initialized");

        // Connect to room
        this.log("RemoteControl", "Connecting to Room...");
        // connect to room
        await room.connect(RemoteControlUtil.remoteControlLiveKitUrl, token);
      });
    }
  }

  // Room Events
  handleTrackSubscribed(track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) {
    this.log("RemoteControl", "Track Subscribed: " + track.kind);
    // Add track to state
    const remoteRoomTracks = this.state.remoteRoomTracks;
    remoteRoomTracks.push(track);
    this.setState({
      remoteRoomTracks: remoteRoomTracks
    });

    // Attach track to devicePreview
    if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
      const devicePreview = document.getElementById("devicePreview");
      if (devicePreview) {
        devicePreview.innerHTML = "";
        const element = track.attach();
        publication.setEnabled(true);
        publication.setSubscribed(true);
        devicePreview.appendChild(element);
        this.log("RemoteControl", "Track Attached");
      } else {
        this.log("RemoteControl", "Device Preview not found", RemoteLogType.ERROR);
      }
    }
  }

  handleTrackUnsubscribed(track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) {
    this.log("RemoteControl", "Track Unsubscribed");
    track.detach();

    // Remove track from state
    const remoteRoomTracks = this.state.remoteRoomTracks;
    const index = remoteRoomTracks.indexOf(track);
    if (index > -1) {
      remoteRoomTracks.splice(index, 1);
    }
    this.setState({
      remoteRoomTracks: remoteRoomTracks
    });
  }

  handleDisconnect(reason?: DisconnectReason | undefined) {
    this.log("RemoteControl", "Disconnected from room, reason: " + reason?.toString(), RemoteLogType.WARNING);
  }

  handleLocalTrackUnpublished(publication: LocalTrackPublication, participant: LocalParticipant) {
    this.log("RemoteControl", "Local Track Unpublished");
  }

  handleParticipantConnected(participant: RemoteParticipant) {
    this.log("RemoteControl", "Participant Connected");
  }

  handleConnectionStateChanged(state: ConnectionState) {
    this.log("RemoteConnectionState", state.toUpperCase().toString(), RemoteLogType.WARNING);
    let connectionState = RemoteControlStatus.UNKNOWN;
    switch (state) {
      case ConnectionState.Connecting:
        connectionState = RemoteControlStatus.CONNECTING;
        break;
      case ConnectionState.Connected:
        connectionState = RemoteControlStatus.CONNECTED;
        break;
      case ConnectionState.Disconnected:
        connectionState = RemoteControlStatus.DISCONNECTED;
        break;
      case ConnectionState.Reconnecting:
        connectionState = RemoteControlStatus.CONNECTING;
        break;
      default:
        connectionState = RemoteControlStatus.UNKNOWN;
        break;
    };

    this.setState({
      remoteControlStatus: connectionState
    });
  }

  handleDataReceived(data: any, participant: any, room: any) {
    // Not logged, as the data sent by "admin" will also be received
  }

  // Device Events
  onMouseDown = (e: any) => {
    // Get position of 'devicePreview' div
    const devicePreview = document.getElementById("devicePreview");
    if (!devicePreview) return;
    const { left, top } = devicePreview.getBoundingClientRect();
    const { clientX, clientY } = e;

    // Get position of mouse
    const x = clientX - left;
    const y = clientY - top;

    // Set state
    this.setState({
      mouseDown: true,
      mouseX: x,
      mouseY: y,
      swipePositions: [], // Reset swipe positions
    });

    // Focus on 'devicePreview'
    devicePreview.focus();
  }

  onMouseUp = (e: any) => {
    // Check if swipe gesture
    if (this.state.swipePositions.length > 1) {
      // Get first and last position
      const firstPosition = this.state.swipePositions[0];
      const lastPosition = this.state.swipePositions[this.state.swipePositions.length - 1];

      // Get difference between first and last position
      const xDiff = lastPosition.x - firstPosition.x;
      const yDiff = lastPosition.y - firstPosition.y;

      // If difference is greater than 10, then it is a swipe gesture
      if (Math.abs(xDiff) > 10 || Math.abs(yDiff) > 10) {
        // Stringify mousePositions
        const mousePositionsStr = JSON.stringify(this.state.swipePositions);

        // Stringify swipe data
        const swipeData: IInputData = {
          type: "swipe",
          data: mousePositionsStr,
        };

        // Send swipe data
        this.sendData(swipeData);

        // Reset swipe positions
        this.setState({
          swipePositions: [],
        });

        // Return
        return;
      }

      // Else it is a tap gesture, it will continue after this if statement
    }

    // console.log(dataPublisher);
    const strData: IInputData = {
      type: "tap",
      data: `{mouseX: ${this.state.mouseX}, mouseY: ${this.state.mouseY}}`,
    };

    // Send tap data
    this.sendData(strData);

    // Reset state
    this.setState({
      mouseDown: false,
      mouseX: e.clientX,
      mouseY: e.clientY,
      swipePositions: [], // Reset swipe positions
    });
  }

  onMouseMove = (e: any) => {
    if (this.state.mouseDown) {
      // Get position of 'devicePreview' div
      const devicePreview = document.getElementById("devicePreview");
      if (!devicePreview) return;
      const { left, top } = devicePreview.getBoundingClientRect();
      const { clientX, clientY } = e;

      // Get position of mouse
      const x = clientX - left;
      const y = clientY - top;

      // Get mouse positions
      const mousePositions = this.state.swipePositions;

      // Set state
      this.setState({
        mouseX: x,
        mouseY: y,
        swipePositions: [...mousePositions, { x: x, y: y }],
      });
    }
  }

  // Room Events
  async sendData(data: IInputData) {
    // Get connected room
    const connectedRoom = this.state.remoteRoom;
    if (connectedRoom == null) {
      this.log("Cannot send data", "No room connected", RemoteLogType.WARNING);
      return;
    }

    // Send data to device
    const responseData = await RemoteControlUtil.sendInputData(
      data,
      connectedRoom
    );

    // Create log
    if (responseData.message === "Data sent successfully") {
      this.log("Data Sent: ", data.type);
    } else {
      this.log("Data", responseData.message, RemoteLogType.ERROR);
      console.log(responseData);
    }
  }

  render() {
    return (
      <div className="RemoteControlPage">
        {/* Toolbar */}
        <RemoteControlToolbar
          remoteControlStatus={this.state.remoteControlStatus}
          errorMessage={this.state.errorMessage}
          onDisconnect={this.disconnect}
        />

        {/* Body */}
        <div className="RemoteControl__Body">
          {/* Body Left */}
          <div className="RemoteControl__Body__Left">
            {/* Search Section */}
            <div className="RemoteControl__SearchSection">
              <FindDeviceComp />
            </div>

            {/* Active Rooms Section */}
            <div className="RemoteControl__DevicesSection">
              <ActiveRoomsComp
                connectedRoom={this.state.remoteRoom}
                onClickConnect={this.onClickConnect}
                disconnect={this.disconnect}
              />
            </div>
          </div>

          {/* Body Right */}
          <div className="RemoteControl__Body__Center">
            <div className={`RemoteControl__Preview ${this.state.deviceFocused ? 'focused' : ''}`}>
              {/* If no video */}
              {this.state.remoteRoomTracks.length === 0 && (
                <div className="RemoteControl__Preview__Info">
                  <h3>No Video</h3>
                  <p>
                    {"No video is being streamed from the device"}
                  </p>
                </div>
              )}

              {/* Device Preview */}
              <div
                id="devicePreview"
                className="RemoteControl__Preview__Device"
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
              >
              </div>

              {/* Device Controls */}
              <div className="RemoteControl_Device_Controls">
                <RemoteDeviceControls
                  sendData={this.sendData}
                  keyEventFocus={this.state.deviceFocused}
                  onKeyEventFocus={() => {
                    this.setState({
                      deviceFocused: !this.state.deviceFocused
                    });
                  }}
                />
              </div>
            </div>

            {/* If no connection connected */}
            {this.state.remoteControlStatus !== RemoteControlStatus.CONNECTED && (
              <div className="RemoteControl__NoDeviceConnected">
                <h3>{("No Device Connect")}</h3>
                <p>
                  {"Connect a device from the list on the left to start remote controlling it"}
                </p>
              </div>
            )}
          </div>

          <div className="RemoteControl__Body__Right" id='remoteControlLogs'>
            {
              this.state.logs.map((log, index) => {
                return (
                  <div className={"RemoteControl__Log " + log.type} key={index}>
                    <p><span>{log.timestamp.toLocaleTimeString()}</span> - <b>{log.title}:</b> {log.message}</p>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    )
  }
}