import { DocumentData } from "firebase/firestore";
import IInputData from "../pages/RemoteControl/Data/InputData";
import { Room } from "livekit-client";

/**
 * RemoteControlUtil is a utility class for remote control feature.
 */
export default abstract class RemoteControlUtil {
  // LiveKit server URL
  public static remoteControlLiveKitUrl = "https://livekit.senifone.com";
  private static remoteControlServerUrl = "https://remote.senifone.com";

  // Get access token for room
  public static getAccessToken(
    requestDocument: DocumentData,
    onComplete: (accessToken: string) => void,
    onError: (error: string) => void
  ) {
    const roomName = requestDocument.id;

    // Get access token for room using fetch
    fetch(this.remoteControlServerUrl + "/participants/generate-access-token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomName: roomName,
        participantName: "Admin",
      }),
    })
      .then(async (response) => {
        console.log(response);
        const responseData = await response.json();
        const accessToken = responseData.accessToken;

        // Connect to room
        onComplete(accessToken);
      })
      .catch((error) => {
        console.log(error);
        onError(error);
      });
  }

  // Send input data to connected device
  public static async sendInputData(
    data: IInputData,
    roomName: Room
  ): Promise<any> {
    const stringData = JSON.stringify(data);

    // Send post request to server
    const response = await fetch(
      RemoteControlUtil.remoteControlServerUrl + "/rooms/send-data",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomName: roomName.name,
          data: stringData,
        }),
      }
    );

    return await response.json();
  }
}
