import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Global CSS
import "./index.css";

// Firebase SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { Provider } from "react-redux";
import store from "./store";
import { UserAuthorizationProvider } from "./context/UserAuthorizationContext";

// For Translation
import "./i18n";

// Global Windows API
export interface IWindowApi {
  isDesktop: boolean;
  closeWindow: () => void;
  onMinimizeButtonClick: () => void;
  onMaximizeButtonClick: () => void;
}

declare global {
  interface Window {
    winApi: IWindowApi;
  }
}

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8oeurKP1dIrnJQh-4pYTs3D7kQwJAR_c",
  authDomain: "senifonelauncher.firebaseapp.com",
  projectId: "senifonelauncher",
  storageBucket: "senifonelauncher.appspot.com",
  messagingSenderId: "210410877228",
  appId: "1:210410877228:web:ae1109bbe99a37d2193c89",
  measurementId: "G-TF6WDEL80W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getFirestore(app);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <UserAuthorizationProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </UserAuthorizationProvider>
  </React.StrictMode>
);

export { auth, database, analytics };
