import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect } from "react";
import Button from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import { database } from "../..";
import "./StaffLogs.css";
import StaffLogsRow from "./StaffLogsRow";
import { RESOURCE } from "../../context/UserAuthorizationContext";
import { useTranslation } from "react-i18next";

export default function StaffLogsPage() {
  const [data, setData] = React.useState<any>([]);
  const [staffMembers, setStaffMembers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [lastDoc, setLastDoc] = React.useState<any>(null);
  const [perPage, setPerPage] = React.useState<number>(10);

  const [appAction, setAppAction] = React.useState<any>("all");
  const [selectedUser, setSelectedUser] = React.useState<any>("all");
  const [fetchingMembers, setFetchingMembers] = React.useState<boolean>(true);

  const getUserAnalytics = async () => {
    if (loading) return;
    setLoading(true);

    // ---------------------- QUERY ----------------------------------
    const q = lastDoc
      ? query(
          collection(database, "UserAnalytics"),
          limit(perPage),
          startAfter(lastDoc)
        )
      : appAction !== "all" && selectedUser !== "all"
      ? query(
          collection(database, "UserAnalytics"),
          limit(perPage),
          where("resource", "==", appAction),
          where("userId", "==", selectedUser)
        )
      : appAction !== "all"
      ? query(
          collection(database, "UserAnalytics"),
          limit(perPage),
          where("resource", "==", appAction)
        )
      : selectedUser !== "all"
      ? query(
          collection(database, "UserAnalytics"),
          where("userId", "==", selectedUser),
          limit(perPage)
        )
      : query(collection(database, "UserAnalytics"), limit(perPage));
    // ---------------------- QUERY ----------------------------------

    await onSnapshot(q, (querySnapshot: any) => {
      if (querySnapshot.docs.length < perPage) setHasMore(false);
      const logsArray: any[] = data;
      if (querySnapshot.docs.length === 0) {
        setLoading(false);
        return;
      }
      querySnapshot.forEach((doc: any) => {
        if (logsArray.findIndex((item) => item.id === doc.id) === -1) {
          logsArray.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setData(logsArray);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUserAnalytics();
  }, [appAction, selectedUser]);

  const getStaffMembers = async () => {
    setFetchingMembers(true);
    const q = query(
      collection(database, "StaffMembers"),
      orderBy("createdAt", "desc")
    );
    await onSnapshot(q, (querySnapshot: any) => {
      if (querySnapshot.docs.length < perPage) setHasMore(false);
      const logsArray: any = staffMembers;
      querySnapshot.forEach((doc: any) => {
        if (logsArray.findIndex((item: any) => item.id === doc.id) === -1) {
          logsArray.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      setStaffMembers(logsArray);
      setFetchingMembers(false);
    });
  };

  useEffect(() => {
    getStaffMembers();
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <div className="StaffPage">
        <Table
          headerRowItems={["#", "Email", "Action", "Outcome", "Performed At"]}
          headerContent={
            <div className="StaffLogs">
              <div className="StaffLogs__Top">
                <div className="StaffLogs__Left">
                  <h3>{t("Staff Logs")}</h3>
                  <Spacer height={6} />
                  <p>{t("List of all staff logs registered with the app")}.</p>
                </div>
                <div className="StaffLogs__Right">
                  {fetchingMembers ? (
                    <div className="Loading">
                      <CircularLoading size={24} strokeWidth={3} />
                    </div>
                  ) : (
                    <div className="StaffLogsSearchBox">
                      <select
                        onChange={(e) => {
                          setSelectedUser(e.target.value);
                          setLastDoc(null);
                          setData([]);
                        }}
                        value={selectedUser}
                      >
                        <option value={"all"}>{t("All")}</option>
                        {staffMembers.map((item: any, index: any) => {
                          return (
                            <option key={index} value={item.id}>
                              {item?.email}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div>
                    <select
                      onChange={(e) => {
                        setAppAction(e.target.value);
                        setLastDoc(null);
                        setHasMore(true);
                        setData([]);
                      }}
                      value={appAction}
                    >
                      <option value={"all"}>{t("All")}</option>
                      {Object.values(RESOURCE).map((resource) => (
                        <option key={resource} value={resource}>
                          {resource}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          }
          footerContent={
            loading ? (
              <div className="Loading">
                <CircularLoading size={24} strokeWidth={3} />
              </div>
            ) : hasMore ? (
              <Button onClick={getUserAnalytics}>{t("Load More")}</Button>
            ) : (
              <div className="NoMore">{t("No more")}</div>
            )
          }
        >
          {data.map((item: any, index: any) => {
            return <StaffLogsRow key={index} index={index} analytic={item} />;
          })}
        </Table>
      </div>
    </>
  );
}
