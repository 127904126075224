import JSZip from "jszip";
import React from "react";
import { HiDocumentAdd, HiSave } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { IFileData } from "../../models/TranslationFileData";
import { setCategories, setTranslations } from "../../slices/translationSlice";
import { AppUtil } from "../../utils/AppUtil";
import Button from "../Button/Button";
import CircularLoading from "../CircularLoading/CircularLoading";
import { useTranslation } from "react-i18next";

export default function TranslationLoadButton() {
  // Redux
  const categories = useSelector(
    (state: any) => state.translationSlice.categories
  );
  const translations = useSelector(
    (state: any) => state.translationSlice.translations
  );
  const isSaving = useSelector((state: any) => state.translationSlice.isSaving);

  // Redux Dispatch
  const dispatch = useDispatch();

  // Button Refs
  const fileButtonRef = React.createRef<HTMLInputElement>();

  // State
  const [fileSelectLoading, setFileSelectLoading] = React.useState(false);

  // Parse `translation` file
  // TODO:: Move to Utils
  const parseTranslationFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const zip = new JSZip();
      zip.loadAsync(e.target?.result as ArrayBuffer).then((zip) => {
        const fileData: IFileData[] = [];
        zip.forEach((relativePath, file) => {
          file.async("string").then((data) => {
            fileData.push({
              name: file.name,
              data: data,
            });
          });
        });

        // Wait for all files to be loaded
        // TODO:: Find a better way to do this
        setTimeout(() => {
          processData(fileData);
        }, 1000);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  // TODO:: Move to Utils
  const processData = (data: IFileData[]) => {
    // TODO:: Set Loading
    AppUtil.parseFileData(data, (categories, translations) => {
      console.log("Processing Zip File");

      // Update data in translationSlice
      dispatch(setCategories(categories));
      dispatch(setTranslations(translations));

      // Save the data to storage
      saveDataToStorage(() => {
        console.log("Data saved");
      });
    });
  };

  const saveDataToStorage = (onSaved?: () => void) => {
    const data = {
      categories: categories,
      translations: translations,
    };
    localStorage.setItem("data", JSON.stringify(data));
    if (onSaved) onSaved();
  };
  const { t } = useTranslation();
  return (
    <>
      {/* The actual file input, this wont be visible in DOM */}
      <input
        ref={fileButtonRef}
        style={{ display: "none" }}
        type="file"
        id="file"
        name="file"
        onChange={(e) => {
          if (e.target.files) {
            setFileSelectLoading(true);
            setTimeout(() => {
              setFileSelectLoading(false);
            }, 1000);

            const file = e.target.files[0];
            parseTranslationFile(file);
          }
        }}
      />
      <Button
        onClick={() => {
          fileButtonRef.current?.click();
        }}
        disabled={fileSelectLoading}
      >
        {fileSelectLoading ? (
          <CircularLoading size={20} strokeWidth={3} />
        ) : (
          <>
            <HiDocumentAdd />
            {t('Load File')}
          </>
        )}
      </Button>
    </>
  );
}
