import {
  collection,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import React from "react";
import { HiEye, HiLocationMarker, HiRefresh } from "react-icons/hi";
import Button from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import { database } from "../..";
import { AppUtil } from "../../utils/AppUtil";
import "./SOSUsagePage.css";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function SOSUsagePage() {
  // Component state
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  // Pagination
  const perPage = 10;
  const [lastDoc, setLastDoc] = React.useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  // List of reset keys as DocumentSnapshot
  const [sosLogs, setDevices] = React.useState<DocumentData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Get reset keys
  const getDevices = async () => {
    if (loading) return;
    setLoading(true);
    const q = lastDoc
      ? query(
          collection(database, "SOSLogs"),
          orderBy("triggeredAt", "desc"),
          limit(perPage),
          startAfter(lastDoc)
        )
      : query(
          collection(database, "SOSLogs"),
          orderBy("triggeredAt", "desc"),
          limit(perPage)
        );
    const result = await getDocs(q);
    if (result.docs.length < perPage) setHasMore(false);
    const logsArray: DocumentData[] = sosLogs;
    result.forEach((doc) => {
      // If the document is already in the list, don't add it again
      // TODO:: This might cause performance issues if the list is too long
      if (logsArray.findIndex((resetKey) => resetKey.id === doc.id) === -1) {
        logsArray.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });
    setLastDoc(result.docs[result.docs.length - 1]);
    setDevices(logsArray);
    setLoading(false);
  };

  // Get reset keys
  React.useEffect(() => {
    if (initialLoad) {
      getDevices();
      setInitialLoad(false);
    }
  }, []);
  const { t } = useTranslation();
  return (
    <div className="SosUsage">
      <Table
        headerRowItems={[
          "#",
          "Device",
          "Call",
          "Contacts",
          "Message",
          "Time",
          "Actions",
        ]}
        headerContent={
          <>
            <div className="DevicesHeader__Left">
              <h3>{t('SOS Triggers')}</h3>
              <Spacer height={6} />
              <p>{t('View all the devices that have triggered the SOS button')}</p>
            </div>
            <div className="DevicesHeader__Right">
              <Button
                onClick={() => {
                  setLastDoc(null);
                  setHasMore(true);
                  setDevices([]);
                  getDevices();
                }}
              >
                <HiRefresh />
                {t('Reload All')}
              </Button>
            </div>
          </>
        }
        footerContent={
          loading ? (
            <div className="Loading">
              <CircularLoading size={24} strokeWidth={3} />
            </div>
          ) : hasMore ? (
            <Button onClick={getDevices}>{t('Load More')}</Button>
          ) : (
            <div className="NoMore">{t('No more logs')}</div>
          )
        }
      >
        {sosLogs.map((log, index) => {
          const triggeredAt = log.triggeredAt.seconds * 1000;
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {/* <td>{log.deviceId ? log.deviceId : "--"}</td> */}
              <td>--</td>
              <td>{log.emergencyNumber ? log.emergencyNumber : "--"}</td>
              <td>
                {log.contacts && log.contacts.length !== 0
                  ? log.contacts
                  : "--"}
              </td>
              <td>{log.message ? log.message : "--"}</td>
              <td>{moment(new Date(triggeredAt)).format("HH:mm, DD/MM/YY")}</td>
              <td className="DeviceRowActions">
                {log.location.latitude != 0 && log.location.longitude != 0 ? (
                  <Button
                    onClick={() => {
                      const latitude = log.location.latitude;
                      const longitude = log.location.longitude;

                      // Open Google Maps
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                        "_blank"
                      );
                    }}
                    disabled={!log.location}
                  >
                    <HiLocationMarker />
                    {t('Location')}
                  </Button>
                ) : (
                  <Button disabled>
                    <HiLocationMarker />
                    {t('Unknown')}
                  </Button>
                )}
                <Spacer width={10} />
                <Button onClick={() => {}} disabled>
                  <HiEye />
                  {t('Device')}
                </Button>
              </td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
}
