import { doc, setDoc } from "firebase/firestore";
import { database } from "..";
import { getAuth } from "@firebase/auth";
import { RESOURCE } from "../context/UserAuthorizationContext";

const num = 10000000000000;

// Update the event in firebase
export const trackAnalytics = async (
  resource: RESOURCE,
  outcome: any,
  success: boolean
) => {
  try {
    const auth = getAuth();
    const user: any = auth.currentUser;
    const userAnalyticsRef = doc(
      database,
      "UserAnalytics",
      `${user.uid}_${num - new Date().getTime()}`
    );

    const obj = {
      userId: user.uid,
      email: user.email,
      displayName: user.providerData[0].displayName,
      photoURL: user.providerData[0].photoURL,
      phoneNumber: user.providerData[0].phoneNumber,
    };
    await setDoc(userAnalyticsRef, {
      user: { ...obj },
      userId: user.uid,
      outcome,
      resource,
      createdAt: new Date(),
      success,
    });

    return;
  } catch (error) {
    return error;
  }
};
