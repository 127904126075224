export const baseURL = "https://senifone-django-service.herokuapp.com/api/senifone/"

export const cloudFunction = "https://us-central1-senifonelauncher.cloudfunctions.net"

export const senifone_base_url = "https://senifone-customer-panel.vercel.app"

export const senifone_auth_timer_key = "auth_timer_key"

export enum SUBSCRIPTION_STATUS {
    ACTIVE = 1,
    BLOCKED = 0,
    LEASE_PAYMENT_NOT_MADE = -1,
  }