import React from "react";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { HiRefresh, HiSearch } from "react-icons/hi";
import Button from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import {
  fetchLeaseUsingSearchString,
  getDocumentsCountInsideCollection,
} from "../../utils/FirestoreUtil";
import { useTranslation } from "react-i18next";
import { database } from "../..";
import LeaseRow from "./LeaseRow";
import LeaseDetailDialog from "../../dialogs/LeaseDialog/leaseDetailDialog";
import { SUBSCRIPTION_STATUS } from "../../constants/siteVariables";

export default function LeasePage() {
  // Component state
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  // Pagination
  const [perPage, setPerPage] = React.useState<number>(10);
  const [lastDoc, setLastDoc] = React.useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  // List of reset keys as DocumentSnapshot
  const [leases, setLeases] = React.useState<DocumentData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Search
  const [searchString, setSearchString] = React.useState<string>("");

  // Counters
  const [totalLeases, setTotalLeases] = React.useState<number>(0);

  // Filters
  const [statusFilter, setStatusFilter] = React.useState("all");

  const { t } = useTranslation();

  const getLeases = async () => {
    if (loading) return;
    setLoading(true);

    let q;

    if (lastDoc) {
      q = query(
        collection(database, "Lease"),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(perPage)
      );
    } else {
      q = query(
        collection(database, "Lease"),
        orderBy("createdAt", "desc"),
        limit(perPage)
      );
    }

    try {
      const result = await getDocs(q);
      const leasesArray: DocumentData[] = [...leases];

      result.docs.forEach((doc) => {
        const existingLeaseIndex = leasesArray.findIndex(
          (lease) => lease.docId === doc.id
        );

        if (existingLeaseIndex === -1) {
          leasesArray.push({
            docId: doc.id,
            ...(doc.data() as Object),
          });
        }
      });

      if (result.docs.length < perPage) {
        setHasMore(false);
      }

      setLastDoc(result.docs[result.docs.length - 1] || null);
      setLeases(leasesArray);
    } catch (error) {
      console.error("Error fetching leases:", error);
    } finally {
      setLoading(false);
    }
  };

  // Get Lease keys
  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      getLeases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      const count = await getDocumentsCountInsideCollection("Lease");
      setTotalLeases(count);
    })();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState<any>();

  const getLeaseUsingSearchString = async () => {
    if (loading) return;
    setLoading(true);
    setLeases([]);
    const requests: Promise<DocumentData>[] = [];
    ["id", "name", "email"].forEach((field) => {
      requests.push(fetchLeaseUsingSearchString(field, searchString));
    });
    await Promise.all(requests).then((values) => {
      setLeases(values.flat());
    });
    setHasMore(false);
    setLastDoc(null);
    setLoading(false);
  };

  const handleClickOpen = async (lease: any) => {
    try {
      const res = await getDoc(doc(database, "Lease", lease.docId as string));
      setSubscriptionDetails(res?.data());
      setOpen(true);
      setLoading(false);
    } catch (error) {
      console.log("🔥💸🌏:::::>>>>", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="DevicesPage">
      <Table
        headerRowItems={[
          "#",
          "ID",
          "User Name",
          "User Email",
          "Status",
          "Next Payment",
          "Remaining Time",
          "Interval",
          "Start Date",
          "Mode",
        ]}
        headerContent={
          <div className="DevicesHeader">
            <div className="DevicesHeader__Top">
              <div className="DevicesHeader__Left">
                <h3>{t("Lease")}</h3>
                <Spacer height={6} />
                <p>{t("List of all lease subscriptions")}.</p>
                <Spacer height={6} />
              </div>
              <div className="DevicesHeader__Right">
                <div className="DevicesHeaderSelectBox">
                  <select
                    onChange={async (e) => {
                      setStatusFilter(e.target.value);
                      setLastDoc(null);
                      setHasMore(true);
                      setLeases([]);

                      if (loading) return;
                      setLoading(true);

                      let q;

                      if (e.target.value === "all") {
                        q = query(
                          collection(database, "Lease"),
                          orderBy("createdAt", "desc"),
                          limit(perPage)
                        );
                      } else {
                        q = query(
                          collection(database, "Lease"),
                          where("status", "==", parseInt(e.target.value)),
                          orderBy("createdAt", "desc"),
                          limit(perPage)
                        );
                      }

                      try {
                        const result = await getDocs(q);
                        const leasesArray: DocumentData[] = [];

                        result.docs.forEach((doc) => {
                          const existingLeaseIndex = leasesArray.findIndex(
                            (lease) => lease.docId === doc.id
                          );

                          if (existingLeaseIndex === -1) {
                            leasesArray.push({
                              docId: doc.id,
                              ...(doc.data() as Object),
                            });
                          }
                        });

                        if (result.docs.length < perPage) {
                          setHasMore(false);
                        }

                        setLastDoc(result.docs[result.docs.length - 1] || null);
                        setLeases(leasesArray);
                      } catch (error) {
                        console.error("Error fetching leases:", error);
                      } finally {
                        setLoading(false);
                      }
                    }}
                    value={statusFilter}
                  >
                    <option value={"all"}>{t("All")}</option>
                    <option value={SUBSCRIPTION_STATUS.ACTIVE}>
                      {t("Active")}
                    </option>
                    <option value={SUBSCRIPTION_STATUS.BLOCKED}>
                      {t("Blocked")}
                    </option>
                    <option value={SUBSCRIPTION_STATUS.LEASE_PAYMENT_NOT_MADE}>
                      {t("Payment Not Made")}
                    </option>
                  </select>
                </div>
                <div className="DevicesSearchBox">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    value={searchString}
                  />
                  <Button
                    className="SearchButton"
                    onClick={() => {
                      setLastDoc(null);
                      setHasMore(true);
                      setLeases([]);
                      getLeaseUsingSearchString();
                    }}
                  >
                    <HiSearch />
                    {t("Search")}
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    setLastDoc(null);
                    setHasMore(true);
                    setLeases([]);
                    getLeases();
                    setSearchString("");
                  }}
                >
                  <HiRefresh />
                  {t("Reload All")}
                </Button>
              </div>
            </div>
            <div className="DevicesHeader__Bottom">
              <p>
                {t("Showing")} {leases.length} {t("leases")} ({totalLeases})
              </p>
              <div className="DevicesHeader_Bottom_Select">
                <label>{"Per Page"}</label>
                <select
                  onChange={(e) => {
                    setLastDoc(null);
                    setPerPage(parseInt(e.target.value));
                    setHasMore(true);
                    setLeases([]);
                    getLeases();
                  }}
                  value={perPage}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        }
        footerContent={
          loading ? (
            <div className="Loading">
              <CircularLoading size={24} strokeWidth={3} />
            </div>
          ) : hasMore ? (
            <Button onClick={getLeases}>{t("Load More")}</Button>
          ) : (
            <div className="NoMore">
              {totalLeases === 0
                ? t("No subscriptions to show")
                : t("No more subscriptions to show")}
            </div>
          )
        }
      >
        {leases.map((lease, index) => {
          return (
            <LeaseRow
              key={index}
              leaseId={lease.id}
              index={index}
              lease={lease}
              handleClickOpen={() => handleClickOpen(lease)}
              docId={lease.docId}
            />
          );
        })}
      </Table>

      {open && (
        <LeaseDetailDialog
          open={open}
          close={handleClose}
          subscriptionDetails={subscriptionDetails}
        />
      )}
    </div>
  );
}
