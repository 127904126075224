import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import RemoteControlStatus from "../Data/RemoteControlStatus";

type Props = {
    remoteControlStatus: RemoteControlStatus;
    errorMessage: string | null;
    onDisconnect: () => void;
}

export default function RemoteControlToolbar(props: Props) {
    const { t } = useTranslation()
    return (
        <div className="RemoteControl__ToolBar">
            {/* Left Toolbar */}
            <div className="RemoteControl__ToolBar__Left">
                {t('Remote Control')}
                <span className="RemoteControl__ToolBar__Status">
                    {props.remoteControlStatus}
                </span>
                {props.errorMessage !== null && (
                    " | Error: " + props.errorMessage
                )}
            </div>

            {/* Right Toolbar */}
            <div className="RemoteControl__ToolBar__Right">
                {props.remoteControlStatus === RemoteControlStatus.CONNECTED && (
                    <Button onClick={props.onDisconnect}>
                        {t('Disconnect')}
                    </Button>
                )}
            </div>
        </div>
    );
}