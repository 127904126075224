import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicIcon from "../../components/DynamicIcon/DynamicIcon";
import Spacer from "../../components/Spacer/Spacer";
import ITranslationData, {
  ICategoryModel,
  ITranslationModel,
} from "../../models/TranslationModel";
import {
  setSelectedCategory,
  updateTranslationModel,
} from "../../slices/translationSlice";
import "./TranslatorPage.css";
import { useTranslation } from "react-i18next";

// TODO:: Improve css naming
// TODO:: Fix Column Resizing
export default function TranslatorPage() {
  // Redux
  const categories: ICategoryModel[] = useSelector(
    (state: any) => state.translationSlice.categories
  );
  const translationDataList: ITranslationData[] = useSelector(
    (state: any) => state.translationSlice.translations
  );
  const selectedCategory: string | undefined = useSelector(
    (state: any) => state.translationSlice.selectedCategory
  );

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [resizingColumn, setResizingColumn] = useState<number>(-1);
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [mousePosition, setMousePosition] = useState<number>(0);

  // Generate the column widths
  useEffect(() => {
    setColumnWidths(generateColWidths(translationDataList.length + 1));
    window.addEventListener("mouseup", () => {
      // console.log("Mouse up");
      setResizingColumn(-1);
    });
    window.addEventListener("mousemove", (e) => {
      setMousePosition(e.clientX);
    });
  }, []);

  // Bind mouse position to resizing
  useEffect(() => {
    handleResize(mousePosition);
  }, [mousePosition]);

  // Generate columns widths base on the number of translations
  const generateColWidths = (cols: number): number[] => {
    const columnWidths = [];
    for (let i = 0; i < cols; i++) columnWidths.push(200);
    return columnWidths;
  };

  // Handle resizing the columns
  const handleResize = (x: number) => {
    if (resizingColumn === -1) return;

    // Get the width of all columns before the resizing column
    let totalWidth = 0;
    if (resizingColumn !== 0) {
      for (let i = 0; i < resizingColumn; i++) {
        totalWidth += columnWidths[i];
      }
    }

    totalWidth += 250;
    columnWidths[resizingColumn] = x - totalWidth;

    // Clamp the width to minimum 200
    if (columnWidths[resizingColumn] < 200) {
      columnWidths[resizingColumn] = 200;
    }

    setColumnWidths(columnWidths);
  };

  // Calculate the total width of the table
  const tableWidth = columnWidths.reduce((a, b) => a + b, 0);
  const { t } = useTranslation();
  return (
    <div className="translation-page">
      {/* Sidebar */}
      {categories.length > 0 ? (
        <div className="TranslatorSidebar">
          <h3 style={{ display: categories.length === 0 ? "none" : "block" }}>
            {t("Categories")}
          </h3>
          <ul>
            {categories.map((category: ICategoryModel) => {
              return (
                <li
                  key={category.id}
                  onClick={() => {
                    dispatch(setSelectedCategory(category.id));
                  }}
                  className={
                    selectedCategory === category.id ? "active" : "inactive"
                  }
                >
                  <DynamicIcon
                    iconName={category.icon}
                    className="category-icon"
                  />
                  {category.name}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {/* Content */}
      {categories.length === 0 ? (
        <div className="NoData">
          <h2>{t('No Translation Data')}</h2>
          <Spacer height={12} />
          <p>
            {t('There is no translation data to display')}.
            <br />
            {t('Load translation data to start editing')}.
          </p>
        </div>
      ) : (
        <div className="translation-content">
          <div className="translation-table" style={{ width: tableWidth }}>
            <div className="table-column" style={{ width: columnWidths[0] }}>
              <div className="table-row-cell key header no-left-border">
                {t('Key')}
                <span
                  className="resize-box"
                  onMouseDown={(e) => {
                    setResizingColumn(0);
                  }}
                >
                  <span className="inner"></span>
                </span>
              </div>
              {
                // Get all the keys for the selected category
                categories
                  .filter((category) => {
                    return category.id === selectedCategory;
                  })
                  .map((category) => {
                    return category.keys.map((key) => {
                      return (
                        <div
                          key={key}
                          className="table-row-cell key no-left-border"
                        >
                          {key}
                        </div>
                      );
                    });
                  })
              }
            </div>
            {translationDataList.map((translation: ITranslationData) => {
              const index = translationDataList.indexOf(translation) + 1;

              return (
                <div
                  key={translation.locale}
                  className="table-column"
                  style={{ width: columnWidths[index] }}
                >
                  <div className="table-row-cell header">
                    {translation.name}
                    <span
                      className="resize-box"
                      onMouseDown={(e) => {
                        console.log("Resizing column " + index);
                        setResizingColumn(index);
                      }}
                    >
                      <span className="inner"></span>
                    </span>
                  </div>
                  {
                    // Get all the keys for the selected category
                    categories
                      .filter((category) => {
                        return category.id === selectedCategory;
                      })
                      .map((category) => {
                        return category.keys.map((key) => {
                          return (
                            <div key={key} className="table-row-cell">
                              <input
                                type="text"
                                value={translation.translations
                                  .filter((translation) => {
                                    return translation.key === key;
                                  })
                                  .map((translation) => {
                                    return translation.value;
                                  })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const index =
                                    translationDataList.indexOf(translation);
                                  const translationData =
                                    translationDataList[index];

                                  // Find translation model by key
                                  const translationModel =
                                    translationData.translations.filter(
                                      (translation: ITranslationModel) => {
                                        return translation.key === key;
                                      }
                                    )[0];

                                  // Update the translations
                                  dispatch(
                                    updateTranslationModel({
                                      dataIndex: index,
                                      translationModelKey: translationModel.key,
                                      value: value,
                                    })
                                  );
                                }}
                                onSubmit={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  // Remove focus from the input
                                  e.currentTarget.blur();

                                  // Prevent the form from submitting
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          );
                        });
                      })
                  }
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
