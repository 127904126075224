import {
  HiDeviceMobile,
  HiDocumentText,
  HiInformationCircle,
  HiKey,
  HiStar,
} from "react-icons/hi";
import { MdAccountCircle, MdSecurity } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setPage } from "../../slices/pageSlice";
import "./HomePage.css";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useUserAuthorization } from "../../context/UserAuthorizationContext";
import { FaUsers } from "react-icons/fa";
import { IoLogoFirebase } from "react-icons/io5";
import { useTranslation } from "react-i18next";

export default function HomePage({ user }: { user: User }) {
  const dispatch = useDispatch();
  const [
    hasEnabledTwoFactorAuthentication,
    setHasEnabledTwoFactorAuthentication,
  ] = useState(false);
  const { userAuthorization } = useUserAuthorization();

  useEffect(() => {
    user
      ?.getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult.claims.firebase?.sign_in_second_factor === "phone") {
          setHasEnabledTwoFactorAuthentication(true);
        } else {
          setHasEnabledTwoFactorAuthentication(false);
        }
      })
      .catch((error) => {
        console.error("Error getting Firebase Authentication token:", error);
      });
  }, [user]);
  const { t } = useTranslation();

  return (
    <div className="HomePage">
      <div className="HomePage__Header">
        <h1>{t("Senifone Admin Tools")}</h1>
        <p>
          {t(
            "Welcome to the Senifone Admin Tools. This is a web application that allows you to manage your Senifone devices, translate the Senifone app, and view and manage reset keys, among other things"
          )}
          .
        </p>
      </div>
      <div className="HomeCardsContainer">
        {!hasEnabledTwoFactorAuthentication && (
          <div
            className="HomeCard"
            onClick={() => {
              dispatch(setPage("mfa-enrollment"));
            }}
          >
            <MdSecurity />
            <h3>{t("Enable MFA")}</h3>
            <p>{t("Multi Factor Authentication")}.</p>
          </div>
        )}
        <div
          className={`HomeCard ${
            !userAuthorization.DEVICES_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.DEVICES_VIEW) {
              return;
            }
            dispatch(setPage("devices"));
          }}
        >
          <HiDeviceMobile />
          <h3>{t("Devices")}</h3>
          <p>{"View and manage devices"}.</p>
        </div>
        <div
          className={`HomeCard ${!userAuthorization.LEASES_VIEW && "disabled"}`}
          onClick={() => {
            if (!userAuthorization.LEASES_VIEW) {
              return;
            }
            dispatch(setPage("lease"));
          }}
        >
          <HiStar />
          <h3>{t("Lease")}</h3>
          <p>{"View lease subscriptions"}.</p>
        </div>
        <div
          className={`HomeCard ${
            !userAuthorization.TRANSLATION_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.TRANSLATION_VIEW) {
              return;
            }
            dispatch(setPage("translator"));
          }}
        >
          <HiDocumentText />
          <h3>{t("Translator")}</h3>
          <p>{t("Translate app strings")}.</p>
        </div>
        <div
          className={`HomeCard ${
            !userAuthorization.RESETKEYS_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.RESETKEYS_VIEW) {
              return;
            }
            dispatch(setPage("key-generator"));
          }}
        >
          <HiKey />
          <h3>{t("Reset Keys")}</h3>
          <p>{t("View and manage reset keys")}.</p>
        </div>
        <div
          className={`HomeCard ${
            !userAuthorization.SOSUSAGE_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.SOSUSAGE_VIEW) {
              return;
            }
            dispatch(setPage("sos-usage"));
          }}
        >
          <HiInformationCircle />
          <h3>{t("SOS Usage")}</h3>
          <p>{t("View SOS usage")}.</p>
        </div>
        <div
          className={`HomeCard ${
            !userAuthorization.STAFFMEMBERS_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.STAFFMEMBERS_VIEW) {
              return;
            }
            dispatch(setPage("staff-members"));
          }}
          style={
            !userAuthorization.STAFFMEMBERS_VIEW ? { display: "none" } : {}
          }
        >
          <FaUsers />
          <h3>{t("Staff Members")}</h3>
          <p>{t("View Staff Members")}.</p>
        </div>
        <div
          className={`HomeCard ${
            !userAuthorization.ANALYTICS_VIEW && "disabled"
          }`}
          onClick={() => {
            if (!userAuthorization.ANALYTICS_VIEW) {
              return;
            }
            dispatch(setPage("track-analytics"));
          }}
          style={!userAuthorization.ANALYTICS_VIEW ? { display: "none" } : {}}
        >
          <IoLogoFirebase />
          <h3>{t("Staff Logs")}</h3>
          <p>{t("View Staff Logs")}.</p>
        </div>
        <div
          className={"HomeCard"}
          onClick={() => {
            dispatch(setPage("account"));
          }}
        >
          <MdAccountCircle />
          <h3>{t("Accont")}</h3>
          <p>{t("View Account Info")}.</p>
        </div>
      </div>
    </div>
  );
}
