import {
  collection,
  DocumentData,
  DocumentSnapshot,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { FaUser } from "react-icons/fa";
import Button from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import { database } from "../..";
import "./StaffMembers.css";
import StaffMembersRow from "./StaffMembersRow";
import CreateUser from "../../dialogs/CreateUser/CreateUser";
import { useTranslation } from "react-i18next";

export default function StaffMembersPage() {
  // Modal
  const [userModal, setUserModal] = React.useState<boolean>(false);
  // Pagination
  const [perPage, setPerPage] = React.useState<number>(10);
  const [lastDoc, setLastDoc] = React.useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  // List of reset keys as DocumentSnapshot
  const [loading, setLoading] = React.useState<boolean>(false);
  const [staffMembers, setStaffMembers] = React.useState<DocumentData[]>([]);

  const getStaffMembers = async () => {
    if (loading) return;
    setLoading(true);
    const q = lastDoc
      ? query(
          collection(database, "StaffMembers"),
          orderBy("createdAt", "desc"),
          limit(perPage),
          startAfter(lastDoc)
        )
      : query(
          collection(database, "StaffMembers"),
          orderBy("createdAt", "desc"),
          limit(perPage)
        );
    await onSnapshot(q, (querySnapshot: any) => {
      if (querySnapshot.docs.length < perPage) setHasMore(false);
      const logsArray: DocumentData[] = staffMembers;
      querySnapshot.forEach((doc: any) => {
        if (logsArray.findIndex((item) => item.id === doc.id) === -1) {
          logsArray.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setStaffMembers(logsArray);
      setLoading(false);
    });
  };

  useEffect(() => {
    getStaffMembers();
  }, []);

  const handleUser = () => {
    setUserModal(true);
  };

  const { t } = useTranslation();

  return (
    <>
      {userModal && (
        <CreateUser
          onClose={() => {
            setUserModal(false);
          }}
        />
      )}
      <div className="StaffPage">
        <Table
          headerRowItems={["#", "UID", "Name", "Email", "Actions"]}
          headerContent={
            <div className="StaffMembers">
              <div className="StaffMembers__Top">
                <div className="StaffMembers__Left">
                  <h3>{t('Staff Members')}</h3>
                  <Spacer height={6} />
                  <p>{t('List of all staff registered with the app')}.</p>
                </div>
                <div className="StaffMembers__Right">
                  <Button onClick={handleUser}>
                    <FaUser />
                    {t('Create User')}
                  </Button>
                </div>
              </div>
            </div>
          }
          footerContent={
            loading ? (
              <div className="Loading">
                <CircularLoading size={24} strokeWidth={3} />
              </div>
            ) : hasMore ? (
              <Button onClick={getStaffMembers}>{t('Load More')}</Button>
            ) : (
              <div className="NoMore">{t('No more Users')}</div>
            )
          }
        >
          {staffMembers.map((member, index) => {
            return (
              <StaffMembersRow
                key={index}
                index={index}
                member={member}
                authorization={member?.authorization}
                setStaffMembers={setStaffMembers}
                staffMembers={staffMembers}
              />
            );
          })}
        </Table>
      </div>
    </>
  );
}
