import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";

interface ComponentProps {
  parentAuthorization: any;
  groupedAuthorization: any;
  setParentAuthorization: any;
  setMember: any;
  member: any;
}

const Permissions = ({
  parentAuthorization,
  groupedAuthorization,
  setParentAuthorization,
  setMember,
  member,
}: ComponentProps) => {
  return (
    <>
      {parentAuthorization && (
        <Stack>
          <Grid container sx={{ overflowY: "scroll", maxHeight: "250px" }}>
            {Object.entries(groupedAuthorization).map(([group, items]: any) => {
              const isParentChecked = parentAuthorization[group];
              return (
                <Grid lg={3} md={3} sm={4} xs={6}>
                  <Box
                    key={group}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 2,
                      fontSize: "0.9rem",
                    }}
                  >
                    <FormControlLabel
                      label={group.charAt(0) + group.slice(1).toLowerCase()}
                      sx={{
                        ".MuiTypography-root": {
                          fontWeight: 550, // add font weight here
                          fontSize: "0.9rem", // add font size here
                        },
                        color: "#F9572B",
                        "&:hover": {
                          color: "white",
                          borderRadius: 10,
                          backgroundColor: "rgb(34 34 34 / 0.54)",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={isParentChecked}
                          onChange={(e) => {
                            const newParentAuthorization = {
                              ...parentAuthorization,
                            };
                            newParentAuthorization[group] = e.target.checked;
                            setParentAuthorization(newParentAuthorization);

                            const newAuthorization = {
                              ...member.authorization,
                            };
                            items.forEach(({ subgroup }: any) => {
                              newAuthorization[`${group}_${subgroup}`] =
                                e.target.checked;
                            });
                            setMember({
                              authorization: newAuthorization,
                            });
                          }}
                          color="warning"
                          sx={{
                            color: "#F9572B",
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        />
                      }
                    />
                    {items.map(({ subgroup, value }: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 4,
                            mt: -1,
                          }}
                        >
                          <FormControlLabel
                            key={`${group}_${subgroup}`}
                            label={
                              subgroup[0].toUpperCase() +
                              subgroup.slice(1).toLowerCase()
                            }
                            sx={{
                              color: "#F9572B",
                              ".MuiTypography-root": {
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              },
                              "&:hover": {
                                color: "white",
                                borderRadius: 10,
                                backgroundColor: "rgb(34 34 34 / 0.54)",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  const newAuthorization = {
                                    ...member.authorization,
                                  };
                                  newAuthorization[`${group}_${subgroup}`] =
                                    e.target.checked;
                                  setMember({
                                    authorization: newAuthorization,
                                  });

                                  const newParentAuthorization = {
                                    ...parentAuthorization,
                                  };
                                  const isGroupChecked = items.every(
                                    ({ subgroup: itemSubgroup }: any) =>
                                      newAuthorization[
                                        `${group}_${itemSubgroup}`
                                      ]
                                  );
                                  newParentAuthorization[group] =
                                    isGroupChecked;
                                  setParentAuthorization(
                                    newParentAuthorization
                                  );
                                }}
                                color="warning"
                                sx={{
                                  color: "#F9572B",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                  },
                                  "&:hover": {
                                    color: "white",
                                  },
                                }}
                              />
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default Permissions;
