export enum appModeFilterTypes {
  ALL = "all",
  PROD = "Prod",
  DEV = "Dev",
}

export enum statusFilterTypes {
  ALL = 2,
  BLOCKED = -1,
  PENDING = 0,
  ACTIVE = 1,
}
