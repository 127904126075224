import {
  User,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useState } from "react";
import { HiArrowRight, HiKey, HiMail } from "react-icons/hi";
import Alert, { AlertType } from "../Alert/Alert";
import Button from "../Button/Button";
import Spacer from "../Spacer/Spacer";
import TextField from "../TextField/TextField";
import "./AuthForm.css";

// Images
import Logo from "../../assets/images/transparent-app_icon.png";
import { setPage } from "../../slices/pageSlice";
import { useDispatch } from "react-redux";
import { continueSigningInViaTwoFA } from "../../utils/FirestoreUtil";
import { useTranslation } from "react-i18next";

/**
 * [AuthForm](./README.md) component is displayed when the user is not logged in.
 *
 * @returns {JSX.Element}
 */
export default function AuthForm({
  setUser,
}: {
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}): JSX.Element {
  // State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const auth = getAuth();

  // Login
  const login = async () => {
    // Check if the email is valid
    if (!email) {
      setError(`${t("Please enter your email")}`);
      return;
    }

    // Check if the password is valid
    if (!password) {
      setError(`${t("Please enter your password")}`);
      return;
    }

    // Login
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // User signed in successfully
      const user = userCredentials.user;
      setUser(user);
      // Check if user's email is verified
      if (user.emailVerified) {
        dispatch(setPage("mfa-enrollment"));
      } else {
        if (auth?.currentUser) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              setError(undefined);
              setSuccess(
                `${t(
                  "Email verification sent successfully. Please verify your email to continue"
                )}`
              );
              setLoading(false);
            })
            .catch((error) => {
              setError(error.message);
              // Handle email verification send error
              // ...
            });
        }
      }
    } catch (error: any) {
      if (error.code === "auth/multi-factor-auth-required") {
        continueSigningInViaTwoFA(
          error,
          dispatch,
          setPage,
          setError,
          setLoading
        );
      } else {
        setError(error.message);
        setLoading(false);
      }
    }
  };
  // Render
  return (
    <div className="AuthFormContainer">
      <div className="AuthForm">
        {/* Header */}
        <div className="AuthForm__Title">
        <img src={Logo} alt="Admin Tools" style={{ width: 'auto' }} />
          <h1>Senifone</h1>
          <p>{t("Sign in to start using Admin Tools")}</p>
        </div>
        <div id="recaptcha-container"></div>
        {/* Error */}
        {error && <Alert style={{ marginTop: "12px" }}>{error}</Alert>}
        {/* Success */}
        {success && (
          <Alert style={{ marginTop: "12px" }} type={AlertType.Success}>
            {success}
          </Alert>
        )}
        {/* Form */}
        <div className="AuthForm__Form">
          <TextField
            type="email"
            placeholder="Email"
            value={email}
            prefix={<HiMail />}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                // Focus the password field
                const passwordField = document.querySelector<HTMLInputElement>(
                  ".AuthForm__Form input[type=password]"
                );
                if (passwordField) {
                  passwordField.focus();
                }
              }
            }}
            fullWidth
          />
          <Spacer height={12} />
          <TextField
            type="password"
            placeholder="Password"
            value={password}
            prefix={<HiKey />}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                // Login
                setLoading(true);
                login();
              }
            }}
            fullWidth
          />
        </div>

        {/* Actions */}
        <div className="AuthForm__Actions">
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              login();
            }}
          >
            {t("Sign In")}
            <HiArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
}
