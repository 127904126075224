import React from "react";
import { DocumentData } from "firebase/firestore";
import { SUBSCRIPTION_STATUS } from "../../constants/siteVariables";
type Props = {
  leaseId: string;
  lease: DocumentData;
  index: number;
  handleClickOpen: any;
  docId: string;
};

export default function LeaseRow(props: Props) {
  const { lease, index, leaseId, handleClickOpen, docId } = props;

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td
        onClick={() => handleClickOpen(docId, leaseId)}
        style={{ cursor: "pointer" }}
      >
        {leaseId}
      </td>
      <td>{lease.name}</td>
      <td>{lease.email}</td>
      <td
        style={
          lease?.status === 1
            ? { color: "#009000" }
            : lease?.status === 0
            ? { color: "#EF0107" }
            : { color: "#F9572B" }
        }
      >
        {SUBSCRIPTION_STATUS[lease?.status] || lease?.status}
      </td>
      <td>{lease.nextPaymentDate}</td>
      <td>{lease.timesRemaining}</td>
      <td>{lease.interval}</td>
      <td>{lease.startDate}</td>
      <td>{lease.mode}</td>
    </tr>
  );
}
