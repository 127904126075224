import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { database } from "..";
import {
  MultiFactorError,
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
} from "firebase/auth";
import { Dispatch } from "react";
import { APP_AUTHORIZATION } from "../context/UserAuthorizationContext";

export default abstract class FirestoreUtil {
  public static RemoteControlRequests: string = "RemoteControlRequests";
  public static UserData: string = "UserData";
  public static SOSLogs: string = "SOSLogs";

  // Get 'RemoteControlRequests' with 'active or pending' status real time
  public static async fetchRemoteControlRequests(
    onComplete: (data: DocumentData[]) => void
  ) {
    const q = query(
      collection(database, FirestoreUtil.RemoteControlRequests),
      where("status", "==", ["active", "pending"]),
      orderBy("timestamp", "desc")
    );

    onSnapshot(q, (querySnapshot) => {
      const documentData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      onComplete(documentData);
    });
  }

  // Get 'RemoteControlRequests' by document id
  public static async fetchRemoteControlRequestById(id: string) {
    return await getDoc(doc(database, FirestoreUtil.RemoteControlRequests, id));
  }
}

// Get devices using search string from firebase
export const fetchDevicesUsingSearchString = async (
  key: string,
  value: string
) => {
  const result = await getDocs(
    query(
      collection(database, "UserData"),
      where(key, "==", value),
      orderBy("registrationDate", "desc")
    )
  );
  const documentData = result.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return documentData;
};

// Get devices using search string from firebase
export const fetchLeaseUsingSearchString = async (
  key: string,
  value: string
) => {
  const result = await getDocs(
    query(
      collection(database, "Lease"),
      where(key, "==", value),
      orderBy("createdAt", "desc")
    )
  );
  const documentData = result.docs.map((doc) => ({
    docId: doc.id,
    ...doc.data(),
  }));
  return documentData;
};

export const getDocumentsCountInsideCollection = async (
  collectionName: string
) => {
  const collectionRef = collection(database, collectionName);
  const querySnapshot = await getDocs(collectionRef);
  return querySnapshot.size;
};

export const enrollUserInMultiFactorAuthentication = async (
  otp: string,
  user: User
) => {
  const cred = PhoneAuthProvider.credential(
    window.verificationId,
    otp as string
  );
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  await multiFactor(user).enroll(multiFactorAssertion);
};

export const completeSignInViaOtp = async (otp: string) => {
  const cred = PhoneAuthProvider.credential(
    window.verificationId,
    otp as string
  );
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  // Complete sign-in.
  await window.resolver.resolveSignIn(multiFactorAssertion);
};

export const setupRecaptcha = () => {
  const auth = getAuth();
  window.recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback: () => {},
    },
    auth
  );
};

export const initiatePhoneNumberVerification = async (
  phoneNumber: string,
  multiFactorSession: MultiFactorSession
) => {
  const auth = getAuth();
  const phoneInfoOptions = {
    phoneNumber: phoneNumber,
    session: multiFactorSession,
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  return phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    window.recaptchaVerifier
  );
};

export const continueSigningInViaTwoFA = async (
  error: MultiFactorError,
  dispatch: Dispatch<any>,
  setPage: any,
  setError: any,
  setLoading: any
) => {
  setupRecaptcha();
  const auth = getAuth();
  const resolver = getMultiFactorResolver(auth, error);
  const selectedIndex = 0;
  // Ask user which second factor to use.
  if (
    resolver.hints[selectedIndex].factorId ===
    PhoneMultiFactorGenerator.FACTOR_ID
  ) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
      .then(function (verificationId) {
        // Ask user for the SMS verification code. Then:
        window.verificationId = verificationId;
        window.resolver = resolver;
        dispatch(setPage("verify-otp"));
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }
};

// Get devices using search string from firebase
export const getStaffMember = async (key: string) => {
  const snapShop = await getDoc(doc(database, "StaffMembers", key));

  if (snapShop.data()) {
    return {
      id: snapShop.id,
      ...snapShop.data(),
    };
  }
  return null;
};

// Get devices using search string from firebase
export const createNewStaffMember = async (
  user: any,
  defaultAuthorization: { [key in APP_AUTHORIZATION]: boolean }
) => {
  await setDoc(doc(database, "StaffMembers", user.uid), {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    userImg: user.photoURL,
    phoneNumber: user.phoneNumber,
    createdAt: new Date(),
    authorization: defaultAuthorization,
  });

  return defaultAuthorization;
};
