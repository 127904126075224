import {
  DocumentData,
  FieldValue,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React from "react";
import IconButton from "../../../components/IconButton/IconButton";
import { HiSearch } from "react-icons/hi";
import FirestoreUtil, {
  fetchDevicesUsingSearchString,
} from "../../../utils/FirestoreUtil";
import CircularLoading from "../../../components/CircularLoading/CircularLoading";
import FindDeviceRow from "./FindDeviceRow";
import { database } from "../../..";
import { useTranslation } from "react-i18next";

export default function FindDeviceComp() {
  // State
  const [devices, setDevices] = React.useState<DocumentData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Search
  const [searchString, setSearchString] = React.useState<string>("");

  // Get devices using search string
  const getDevicesUsingSearchString = async () => {
    if (loading) return;
    setLoading(true);
    setDevices([]);
    const requests: Promise<DocumentData>[] = [];
    ["pin", "deviceImei", "uniqueId"].forEach((field) => {
      requests.push(fetchDevicesUsingSearchString(field, searchString));
    });
    await Promise.all(requests).then((values) => {
      setDevices(values.flat());
    });
    setLoading(false);
  };

  // Send request
  const sendRequest = async (deviceId: string, pin: string) => {
    // Send Request
    console.log("Sending request to: " + deviceId);

    // Check if 'RemoteControlRequests' collection has a document with the same deviceId
    // If yes, then update the document
    // If no, then create a new document
    const existingRequest = await FirestoreUtil.fetchRemoteControlRequestById(
      deviceId
    );
    if (existingRequest.exists()) {
      // Update the document
      console.log("Updating existing request");

      // Check if the status is 'pending or active'
      // If yes, then do nothing
      // If no, then update the document
      if (
        existingRequest.data().status === "pending" ||
        existingRequest.data().status === "active"
      ) {
        console.log("Request is already pending or active");
      }

      await setDoc(
        doc(database, FirestoreUtil.RemoteControlRequests, deviceId),
        {
          deviceId: deviceId,
          pin: pin,
          status: "pending",
          timestamp: serverTimestamp(),
        },
        { merge: true }
      );
    } else {
      // Create a new document
      console.log("Request does not exist, Creating new request");
      await setDoc(
        doc(database, FirestoreUtil.RemoteControlRequests, deviceId),
        {
          deviceId: deviceId,
          pin: pin,
          status: "pending",
          timestamp: serverTimestamp(),
        }
      );
    }
  };
  const { t } = useTranslation();
  return (
    <div className="FindDeviceComp">
      <div className="FindDeviceComp__SearchBar">
        <input
          type="text"
          placeholder="Search Device using PIN"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getDevicesUsingSearchString();
            }
          }}
        />
        <IconButton
          onClick={() => {
            getDevicesUsingSearchString();
          }}
        >
          <HiSearch />
        </IconButton>
      </div>
      <div className="FindDeviceComp__List">
        {loading && (
          <div className="FindDeviceComp__Center">
            <CircularLoading size={32} strokeWidth={2} />
          </div>
        )}
        {devices.length === 0 && (
          <div className="FindDeviceComp__Center">{t('No Devices Found')}</div>
        )}
        {devices.map((device, index) => {
          return (
            <FindDeviceRow
              key={index}
              deviceId={device.id}
              index={index}
              device={device}
              sendRequest={(deviceId: string, pin: string) => {
                sendRequest(deviceId, pin);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
