import * as React from "react";
import SubscriptionDetails from "./components/subscriptionDetails";
import PaymentTable from "./components/paymentTable";
import CloudService from "../../services/Cloud";
import Button, { ButtonType } from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import "./leaseDetail.css";
import CircularLoading from "../../components/CircularLoading/CircularLoading";

export default function LeaseDetailDialog({
  close,
  open,
  subscriptionDetails,
}: any) {
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchPayments = async () => {
    try {
      const res = await CloudService.paymentDetails({
        custId: subscriptionDetails?.customerId,
        subId: subscriptionDetails?.id,
      });
      setPaymentDetails(res?.data?._embedded?.payments);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!subscriptionDetails?.customerId && !subscriptionDetails?.id) {
      return;
    }
    fetchPayments();
  }, [subscriptionDetails?.customerId, subscriptionDetails?.id]);

  return (
    <>
      <div className="LeaseDialogOverlay" />
      <div className="LeaseDialog">
        <div className="LeaseDialogContainer">
          <div className="LeaseDialogContent">
            <div>
              <SubscriptionDetails subscriptionDetails={subscriptionDetails} />
            </div>
            <div className="PaymentTableParent">
              {!loading ? (
                <>
                  {paymentDetails?.length !== 0 ? (
                    <PaymentTable paymentDetails={paymentDetails} />
                  ) : (
                    <h3>No Payments yet!</h3>
                  )}
                </>
              ) : (
                <div className="PaymentTableLoader">
                  <CircularLoading size={24} strokeWidth={2} />
                </div>
              )}
            </div>
          </div>
          <div className="LeaseDialogActions">
            <Button onClick={close} type={ButtonType.Info}>
              {t("Close")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
