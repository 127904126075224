import { Divider } from "@mui/material";
import {
  collection,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import React from "react";
import { HiRefresh } from "react-icons/hi";
import Button from "../../components/Button/Button";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import Spacer from "../../components/Spacer/Spacer";
import Table from "../../components/Table/Table";
import { database } from "../..";
import { AppUtil } from "../../utils/AppUtil";
import "./KeyGeneratorPage.css";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function KeyGeneratorPage() {
  // Component state
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  // Pagination
  const perPage = 10;
  const [lastDoc, setLastDoc] = React.useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  // List of reset keys as DocumentSnapshot
  const [resetKeys, setResetKeys] = React.useState<DocumentData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Get reset keys
  const getResetKeys = async () => {
    if (loading) return;
    setLoading(true);
    const q = lastDoc
      ? query(
          collection(database, "ResetKeys"),
          orderBy("generatedAt", "desc"),
          limit(perPage),
          startAfter(lastDoc)
        )
      : query(
          collection(database, "ResetKeys"),
          orderBy("generatedAt", "desc"),
          limit(perPage)
        );
    const result = await getDocs(q);
    if (result.docs.length < perPage) setHasMore(false);
    const resetKeysArray: DocumentData[] = resetKeys;
    result.forEach((doc) => {
      // If the document is already in the list, don't add it again
      // TODO:: This might cause performance issues if the list is too long
      if (
        resetKeysArray.findIndex((resetKey) => resetKey.id === doc.id) === -1
      ) {
        resetKeysArray.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });
    setLastDoc(result.docs[result.docs.length - 1]);
    setResetKeys(resetKeysArray);
    setLoading(false);
  };

  // Get reset keys
  React.useEffect(() => {
    if (initialLoad) {
      getResetKeys();
      setInitialLoad(false);
    }
  }, []);
  const { t } = useTranslation();
  return (
    <div className="KeyGeneratorPage">
      <Table
        headerContent={
          <>
            <div>
              <h3>{t("Reset Keys")}</h3>
              <Spacer height={6} />
              <p>
                {t(
                  "Here are the list of reset keys generated for users who have lost their PIN for their device Restrictive mode"
                )}
                .
              </p>
            </div>
            <div>
              <Button
                onClick={() => {
                  setLastDoc(null);
                  setHasMore(true);
                  setResetKeys([]);
                  getResetKeys();
                }}
              >
                <HiRefresh />
                {t('Reload All')}
              </Button>
            </div>
          </>
        }
        headerRowItems={[
          "#",
          "User ID",
          "Name",
          "User Code",
          "Recovery Code",
          "Reset Key",
          "Generated Date",
        ]}
        footerContent={
          loading ? (
            <div className="Loading">
              <CircularLoading size={24} strokeWidth={3} />
            </div>
          ) : hasMore ? (
            <Button onClick={getResetKeys}>{t('Load More')}</Button>
          ) : (
            <div className="NoMore">{t('No more reset keys')}</div>
          )
        }
      >
        {resetKeys.map((resetKey, index) => {
          const generatedAt = resetKey.generatedAt.seconds * 1000;
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{resetKey.userID ? resetKey.userID : "--"}</td>
              <td>{resetKey.userName ? resetKey.userName : "--"}</td>
              <td>{resetKey.userCode ? resetKey.userCode : "--"}</td>
              <td>{resetKey.recoveryCode}</td>
              <td>{resetKey.resetKey}</td>
              <td>{moment(new Date(generatedAt)).format("HH:mm, DD/MM/YY")}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
}
