import "./StatsPage.css";
import StatsDevices from "./Components/StatsDevices";
import StatsSOS from "./Components/StatsSOS";
import { useTranslation } from "react-i18next";

export default function StatsPage() {
  const { t } = useTranslation();
  return (
    <div className="StatsPage">
      <h1>{t('Detailed Statistics')}</h1>

      <div className="StatsPage__Grid">
        <div className="StatsPage__GridItem">
          <h2>{t("Placeholder")}</h2>
        </div>
        <StatsDevices />
        <div className="StatsPage__GridItem">
          <h2>{t("Placeholder")}</h2>
        </div>
        <StatsSOS />
      </div>
    </div>
  );
}
