import { useEffect, useState } from "react";
import { HiQrcode, HiX } from "react-icons/hi";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import "./QRGenerationDialog.css";
import QRCodeService from "../../services/QrCode/index";
// @ts-ignore
import QRCode from "react-image-charts-qrcode";
import CircularLoading from "../../components/CircularLoading/CircularLoading";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};

export default function QRGenerationDialog(props: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [qrCodes, setQrCodes] = useState([]);
  const [qr, setQr] = useState(0);
  const [qrView, setQrView] = useState();

  useEffect(() => {
    QRCodeService.getQrCodes().then((qrCodes) => {
      setQrCodes(qrCodes);
      setLoading(false);
    });
  }, []);

  const generateQr = (e: any) => {
    QRCodeService.generateQrCode(e).then((res) => {
      setQrView(res?.data);
    });
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="DialogOverlay" />
      <div className="DialogContainer">
        <div className="QRGenerationDialog">
          <h3>{t('Generate QR Code')}</h3>
          <Spacer height={4} />
          <p>{t('Select the type of QR Code you want to generate')}.</p>
          <Spacer height={24} />
          {!loading ? (
            <select
              onChange={(e: any) => {
                const value = JSON.parse(e.target.value);
                if (value !== 0) {
                  const temp: any = qrCodes.find(
                    (qrCode: any) => qrCode.id === value.id
                  );
                  setQr(temp);
                } else {
                  setQr(0);
                }
                setQrView(undefined);
              }}
              defaultValue={0}
            >
              <option value={0}>{t('None')}</option>
              {qrCodes?.map((qrCode: any) => {
                return (
                  <option value={JSON.stringify(qrCode)}>{qrCode?.name}</option>
                );
              })}
            </select>
          ) : (
            <CircularLoading size={24} strokeWidth={2} />
          )}
          <Spacer height={24} />
          {qr !== 0 && qrView && <QRCode data={qrView} size={400} />}

          {/* Error Message */}
          {errorMessage.length > 0 && <Alert>{errorMessage}</Alert>}

          {/* Actions */}
          <div className="generate-key-dialog-buttons">
            {/* Generate Key */}
            <Button
              disabled={loading || qr === 0}
              loading={generating}
              onClick={async () => {
                if (qr === 0) {
                  return;
                }
                generateQr(qr);
              }}
            >
              <HiQrcode />
              {('Generate')}
            </Button>

            {/* Close Button */}
            <Button
              onClick={() => {
                props.onClose();
              }}
            >
              <HiX />
              {t('Close')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
